<template>
  <div style="background: #fff">
    <van-nav-bar title="支付结果"></van-nav-bar>
    <van-loading size="24px" vertical style="top: 10rem" v-show="pageLoading"
      >加载中...</van-loading
    >
    <a-result
      status="success"
      title="支付成功"
      sub-title=""
      v-if="status && !pageLoading && message"
    >
    </a-result>
    <a-result
      status="error"
      title="支付失败"
      sub-title=""
      v-if="!status && !pageLoading && message"
    >
      <div class="desc">
        <p style="font-size: 0.8rem; text-align: center">
          <strong>{{ message }}</strong>
        </p>
      </div>
    </a-result>
    <a-result
      sub-title="支付可能存在延迟，若您已支付成功，请点击查询结果"
      v-if="!status && !message && !pageLoading"
    >
      <div class="desc">
        <p style="font-size: 0.8rem; text-align: center">
          <a-button v-if="isShow" @click="getOrderStatus(1)">查询结果</a-button>
          <strong v-else>{{ message }}</strong>
        </p>
      </div>
    </a-result>
  </div>
</template>
<script>
import qs from "qs";
export default {
  data() {
    return {
      orderId: "",
      status: false,
      pageLoading: true,
      message: "",
      isShow: true,
    };
  },
  mounted() {
    this.resetComponentState();
    this.orderId = this.$route.query.order_sn;
    console.log("参数", this.$route.query.order_sn);
    setTimeout(() => {
      this.getOrderStatus();
    }, 1000);
  },
  methods: {
    onBack() {
      this.$router.push("/pocket");
    },
    resetComponentState() {
      this.orderId = "";
      this.status = false;
      this.pageLoading = true;
      this.message = "";
    },
    getOrderStatus(type) {
      if (type) {
        this.pageLoading = true;
        this.isShow = false;
      }
      this.$axios
        .post(
          this.$api.server_api.v1.ALIPAY_STATUS,
          { order_sn: this.orderId }

          // qs.stringify({
          //   order_sn: this.orderId,
          // })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.status = true;
            this.message = res.data.message;
          } else {
            this.status = false;
            if (this.isShow) {
              setTimeout(() => {
                this.getOrderStatus();
              }, 3000);
            } else {
              this.message = res.data.message;
            }
          }
          this.pageLoading = false;
        });
    },
  },
};
</script>
