<template>
  <!-- 功能介绍 -->
  <div>
    <Header :nowActive="nowActive" v-if="!isMobile"></Header>
    <PublicBanner v-if="!isMobile"></PublicBanner>
    <sidebar></sidebar>
	
    <div style="width: 100%">
      <img style="width: 100%" src="../assets/img/functionIntroduction.jpg" />
    </div>
	<div v-if="!isMobile"><productService ></productService></div>
    <Publicfooter ref="footer" v-if="!isMobile"></Publicfooter>
  </div>
</template>

<script>
import Header from "../components/header.vue";
import PublicBanner from "../components/banner.vue";
import Publicfooter from "../components/footer.vue";
import sidebar from "../components/sidebar.vue";
import productService from "./productService.vue";

export default {
  components: {
    Header,
    PublicBanner,
    Publicfooter,
	productService,
	sidebar
  },
  data() {
    return {
      nowActive: ["functionIntroduction"],
	  isMobile:false,
    };
  },
  mounted() {
  	this.isMobile = this.$refs.footer.isMobile;
  	// 监听事件添加和销毁
  	this.$nextTick(() => {
  	// document.addEventListener('scroll', this.handleScroll)
  	// console.log(this.bannerList)
  	})
  },
  beforeDestroy() {
    // document.removeEventListener('scroll', this.handleScroll)
  },
  methods:{
	  // 滚动事件
	  	 
	  handleScroll () {
	        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop//获取滚动距离
	        this.scrollTop = scrollTop//data里return了一个全局的scrollTop
	  			// console.log(scrollTop)
	        //这边距离我是通过打印scrollTop大致自己粗略的计算了下，用来判断滚轮滚动相应的距离改变导航栏对应菜单样式
	  			if(this.scrollflag){
	  			this.$nextTick(() => {
	  				if(isMobile==false){
	  					if (scrollTop > document.getElementById("hometab1").offsetTop -65 ) {
	  								   this.activeindex = 1
	  					}
	  				}else{
	  			if (scrollTop < 487) {
	  				this.activeindex = 0;
	  			}
	       if (scrollTop > document.getElementById("block1").offsetTop -65 ) {
	  			   this.activeindex = 1
	       }
	       
	  		   // console.log(this.activeindex)
	  		   }
	  		   })
	  		   }
	      },
	  		  //跳转操作
	   scrollTopto(val){
	  			this.scrollflag = false
	  			let propval = {
	  						  name :val.name,
	  						  tabNum :val.tabNum,
	  						  index: val.index
	  							}
	  							this.activeindex = propval.index 
	  			let scrollheight =  document.getElementById(propval.tabNum).offsetTop
	  			this.$nextTick(()=>{
	  				if(propval.tabNum=="longpage"){
	  					document.scrollingElement.scrollTop = 0
	  					}else{
	  						document.scrollingElement.scrollTop = scrollheight - 60
	  					}
	  					this.scrollflag = true
	  			})
	  			 },
	  			 
	  
  }
};
</script>

<style>
</style>
