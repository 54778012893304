<template>
  <!-- <div class="sideNavigation">
	  <div class="sidebar-tab select">
		  <i class="dian"></i><span>功能介紹</span>
		  <span class="line"></span>
		  </div>
	  <div class="sidebar-tab">功能介紹</div>
  </div> -->
</template>
<script>
export default {
  data(){
    return{
      bannerList: [],
      value1: 1,
	  scrollTop:'',
    };
  },
  props:[
	  'tabname', 'tabsid',],
  mounted(){
// 监听事件添加和销毁
  	this.$nextTick(() => {
  	// document.addEventListener('scroll', this.handleScroll)
  	// console.log(this.bannerList)
  	})
  },
  beforeDestroy() {
    // document.removeEventListener('scroll', this.handleScroll)
  },
  watch(){
  },
  methods:{
    // 滚动事件
    handleScroll () {
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop//获取滚动距离
          this.scrollTop = scrollTop//data里return了一个全局的scrollTop
    			// console.log(scrollTop)
          //这边距离我是通过打印scrollTop大致自己粗略的计算了下，用来判断滚轮滚动相应的距离改变导航栏对应菜单样式
    			this.$nextTick(() => {
    		if(isMobile==false){
    			if (scrollTop > document.getElementById(this.tabid).offsetTop -65 ) {
    					//    this.tabname = 
    			}else{
    		
    			}
        
         
    		   // console.log(this.activeindex)
    		   }
    		   })
        },
    		  //跳转操作
     scrollTopto(val){
    			this.scrollflag = false
    			let propval = {
    						  name :val.name,
    						  tabNum :val.tabNum,
    						  index: val.index
    							}
    							this.activeindex = propval.index 
    			let scrollheight =  document.getElementById(propval.tabNum).offsetTop
    			this.$nextTick(()=>{
    				if(propval.tabNum=="longpage"){
    					document.scrollingElement.scrollTop = 0
    					}else{
    						document.scrollingElement.scrollTop = scrollheight - 60
    					}
    					this.scrollflag = true
    			})
    			 },
  }
}
</script>
<style scoped>
/* For demo */
.sideNavigation{
 position: fixed;
 top:45%;
 left:5%;
}
.sidebar-tab{
 font-size: 16px;
 font-family: Source Han Sans CN;
 font-weight: 400;
 color:#3D3D3D ;
 position: relative;
}
.dian{
	width: 8px;
    height: 8px;
    opacity: 1;
    background: #3688A1;
    border-radius: 4px;
    display: inline-block;
    position: absolute;
    left: -10px;
    top: 45%;
}
.sidebar-tab span.line{
 width: 75%;
 height: 2px;
 background:#3688A1 ;
display: block; 
}
 .sidebar-tab.select{
	 color:#3688A1 ;
  -webkit-animation: changefontsize 0.5s linear;
 -webkit-animation-iteration-count:1;
 -webkit-animation-fill-mode:forwards;
 }
 @keyframes changefontsize
 {
 	0% {font-size:16px;}
 	100% {font-size:24px;}
 }
</style>