<template>
  <div>
    <Header :nowActive="nowActive"></Header>
    <div class="loginCont">
      <div style="background:#fff;width:30%;padding:3%;padding-right:3%">
        <a-tabs size='large' style="padding-right" default-active-key="1">
          <a-tab-pane key="1"  tab="账号登录">
            <a-input size="large" style="margin-top: 20px" placeholder="请输入手机号码" />
            <a-input size="large" type="password" style="margin-top: 20px" placeholder="请输入密码" />
            <p @click="forget" style="color:#1890ff;margin-top:20px;font-weight:500;cursor:pointer">忘记密码？</p>
            <a-button size="large" type='primary' style="width:100%;margin-top: 50px">登录</a-button>
            <p style="text-align:center;margin-top:20px;color:#c2c2c2c2">还没有账号？<span style="cursor:pointer;color:#666" @click="register">快速注册</span></p>
          </a-tab-pane>
          <a-tab-pane key="2" tab="动态密码登录" force-render>
            <a-input size="large" style="margin-top: 20px" placeholder="请输入手机号码" />
            <a-input size="large" type="password" addon-after="发送验证码"  style="margin-top: 20px" placeholder="请输入验证码" />
            <p  @click="forget" style="color:#1890ff;margin-top:20px;font-weight:500;cursor:pointer">忘记密码？</p>
            <a-button size="large" type='primary' style="width:100%;margin-top: 50px">登录</a-button>
            <p style="text-align:center;margin-top:20px;color:#c2c2c2">还没有账号？<span style="cursor:pointer;color:#666" @click="register"> 快速注册</span></p>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <Publicfooter></Publicfooter>
  </div>
</template>
<script>
import Header from '../components/header' 
import Publicfooter from '../components/footer'
export default {
  components:{
    Header,
    Publicfooter
  },
  data(){
    return{
      nowActive: [5]
    }
  },
  methods:{
    register(){
      this.$router.push('/register')
    },
    forget(){
      this.$router.push('/forget')
    }
  }
}
</script>
<style scoped>
.loginCont{
  width:100%;
  height: 700px;
  background: url('../assets/img/loginbg.png') no-repeat center;
  background-size: 100% 120%;
  padding-top: 5%;
  padding-left: 10%
}
</style>