<template>
    <div>
        <Header :nowActive = "nowActive"></Header>
        <!-- PC端 -->
        <div v-if="isMobile == false">
            <div class="bannerImg">
                <img src="../assets/img/fwsjmbanner.svg" />
            </div>
            <div class="pioneer">
                <div class="pioneer_text">作为创业者：一定是选择大于努力</div>
                <div class="pioneer_card_frame">
                    <div class="pioneer_card">
                        <div class="card_image">
                            <img src="../assets/img/fk.svg" alt="">
                        </div>
                        <div class="card_text">一个风口</div>
                        <div class="card_txt">万亿级本地生活服务市场</div>
                        <div class="card_line"></div>
                    </div>
                    <div class="pioneer_card">
                        <div class="card_image">
                            <img src="../assets/img/jh.svg" alt="">
                        </div>
                        <div class="card_text">一个机会</div>
                        <div class="card_txt">新冠肺炎让更多人依赖同城配送服务</div>
                        <div class="card_line"></div>
                    </div>
                    <div class="pioneer_card">
                        <div class="card_image">
                            <img src="../assets/img/td.svg" alt="">
                        </div>
                        <div class="card_text">一个团队</div>
                        <div class="card_txt">成熟的互联网创业团队数百个优秀合作伙伴一起</div>
                        <div class="card_line"></div>
                    </div>
                    <div class="pioneer_card">
                        <div class="card_image">
                            <img src="../assets/img/cs.svg" alt="">
                        </div>
                        <div class="card_text">一座城市</div>
                        <div class="card_txt">在创业之城，互联网创业者乐园！</div>
                        <div class="card_line"></div>
                    </div>
                </div>
            </div>
            <!-- <div class="serviceProvider">
                <div class="pioneer_text">优秀服务商展示</div>
                <div class="serviceProvider_card_frame">
                    <div class="serviceProvider_card">
                        <div class="serviceProvider_img_card">
                            <img src="../assets/img/fws1.svg" alt="">
                        </div>
                        <div class="serviceProvider_card_text">苏州代理商：万总</div>
                        <div class="serviceProvider_cards_text">日均订单金额15000+</div>
                        <div class="serviceProvider_cards_txt">苏州代理商万总注重品牌客户开发，签约维尔纳斯全国300家门店，日均15000+</div>
                    </div>
                    <div class="serviceProvider_card">
                        <div class="serviceProvider_img_card">
                            <img src="../assets/img/fws2.svg" alt="">
                        </div>
                        <div class="serviceProvider_card_text">苏州代理商：万总</div>
                        <div class="serviceProvider_cards_text">日均订单金额15000+</div>
                        <div class="serviceProvider_cards_txt">苏州代理商万总注重品牌客户开发，签约维尔纳斯全国300家门店，日均15000+</div>
                    </div>
                    <div class="serviceProvider_card">
                        <div class="serviceProvider_img_card">
                            <img src="../assets/img/fws3.svg" alt="">
                        </div>
                        <div class="serviceProvider_card_text">苏州代理商：万总</div>
                        <div class="serviceProvider_cards_text">日均订单金额15000+</div>
                        <div class="serviceProvider_cards_txt">苏州代理商万总注重品牌客户开发，签约维尔纳斯全国300家门店，日均15000+</div>
                    </div>
                    <div class="serviceProvider_card">
                        <div class="serviceProvider_img_card">
                            <img src="../assets/img/fws4.svg" alt="">
                        </div>
                        <div class="serviceProvider_card_text">苏州代理商：万总</div>
                        <div class="serviceProvider_cards_text">日均订单金额15000+</div>
                        <div class="serviceProvider_cards_txt">苏州代理商万总注重品牌客户开发，签约维尔纳斯全国300家门店，日均15000+</div>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- 移动端 -->
        <div v-else>
            <div class="bannerImg">
                <img src="../assets/img/fwsjmbanner1.svg" />
                <img src="../assets/img/fwsjmbanner2.svg" />
            </div>
            <div class="mobiles_pioneer">
                <div class="mobiles_text">作为创业者：一定是选择大于努力</div>
                <div class="mobiles_card_frame">
                    <div class="mobiles_card">
                        <div class="mobiles_image">
                            <img src="../assets/img/fk.svg" alt="">
                        </div>
                        <div class="mobiles_cards_text">一个风口</div>
                        <div class="mobiles_cards_txt">万亿级本地生活服务市场</div>
                        <div class="mobiles_cards_line"></div>
                    </div>
                    <div class="mobiles_card">
                        <div class="mobiles_image">
                            <img src="../assets/img/jh.svg" alt="">
                        </div>
                        <div class="mobiles_cards_text">一个机会</div>
                        <div class="mobiles_cards_txt">新冠肺炎让更多人依赖同城配送服务</div>
                        <div class="mobiles_cards_line"></div>
                    </div>
                    <div class="mobiles_card">
                        <div class="mobiles_image">
                            <img src="../assets/img/td.svg" alt="">
                        </div>
                        <div class="mobiles_cards_text">一个团队</div>
                        <div class="mobiles_cards_txt">成熟的互联网创业团队数百个优秀合作伙伴一起</div>
                        <div class="mobiles_cards_line"></div>
                    </div>
                    <div class="mobiles_card">
                        <div class="mobiles_image">
                            <img src="../assets/img/td.svg" alt="">
                        </div>
                        <div class="mobiles_cards_text">一座城市</div>
                        <div class="mobiles_cards_txt">在创业之城，互联网创业者乐园！</div>
                        <div class="mobiles_cards_line"></div>
                    </div>
                </div>
            </div>
            <!-- <div class="mobiles_service">
                <div class="mobiles_text">优秀服务商展示</div>
                <div class="mobiles_service_card_frame">
                    <div class="mobiles_provider_card">
                        <div class="mobiles_img_provider_card">
                            <img src="../assets/img/fws1.svg" alt="">
                        </div>
                        <div class="mobiles_provider_card_text">苏州代理商：万总</div>
                        <div class="mobiles_provider_cards_text">日均订单金额15000+</div>
                        <div class="mobiles_provider_cards_txt">苏州代理商万总注重品牌客户开发，签约维尔纳斯全国300家门店，日均15000+</div>
                    </div>
                    <div class="mobiles_provider_card">
                        <div class="mobiles_img_provider_card">
                            <img src="../assets/img/fws2.svg" alt="">
                        </div>
                        <div class="mobiles_provider_card_text">苏州代理商：万总</div>
                        <div class="mobiles_provider_cards_text">日均订单金额15000+</div>
                        <div class="mobiles_provider_cards_txt">苏州代理商万总注重品牌客户开发，签约维尔纳斯全国300家门店，日均15000+</div>
                    </div>
                    <div class="mobiles_provider_card">
                        <div class="mobiles_img_provider_card">
                            <img src="../assets/img/fws3.svg" alt="">
                        </div>
                        <div class="mobiles_provider_card_text">苏州代理商：万总</div>
                        <div class="mobiles_provider_cards_text">日均订单金额15000+</div>
                        <div class="mobiles_provider_cards_txt">苏州代理商万总注重品牌客户开发，签约维尔纳斯全国300家门店，日均15000+</div>
                    </div>
                    <div class="mobiles_provider_card">
                        <div class="mobiles_img_provider_card">
                            <img src="../assets/img/fws4.svg" alt="">
                        </div>
                        <div class="mobiles_provider_card_text">苏州代理商：万总</div>
                        <div class="mobiles_provider_cards_text">日均订单金额15000+</div>
                        <div class="mobiles_provider_cards_txt">苏州代理商万总注重品牌客户开发，签约维尔纳斯全国300家门店，日均15000+</div>
                    </div>
                    
                </div>
            </div> -->
        </div>
        <Publicfooter></Publicfooter>
    </div>
</template>

<script>
import Header from '../components/header'
import Publicfooter  from '../components/footer'
export default {
    data () {
        return {
            nowActive: ["serviceProviderJoining"],
            isMobile: false,
        }
    },
    components:{
        Header,
        Publicfooter
    },
    mounted() {
        if (this._isMobile()) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }
    },
    methods: {
        _isMobile() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            return flag;
        },
    },
}
</script>

<style scoped>
/* ***移动端样式Start*** */
.mobiles_text {
    padding-top: 50px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 3px;
    color: #333333;
}
.mobiles_card_frame {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 5% 5% 5%;
}
.mobiles_card {
    margin-top: 30px;
    text-align: center;
    width: 48%;
    height: 200px;
    border-radius: 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 40px 20px rgba(95, 95, 95, 0.04);
}
.mobiles_image {
    margin-top: 30px;
}
.mobiles_image img {
    width: 50px;
    height: 50px;
}
.mobiles_cards_text {
    margin-top: 16px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 2px;
    color: #333333;
}
.mobiles_cards_txt {
    font-size: 12px;
    width: 90%;
    height: 34px;
    margin: 2px auto 0;
}
.mobiles_cards_line {
    margin: 16px;
    width: 40px;
    height: 2px;
    background: #3688a1;
}
.mobiles_service {
    background: #fefefe;
    margin-bottom: 20px;
}
.mobiles_service_card_frame {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 5%;
}
.mobiles_provider_card {
    margin-top: 30px;
    text-align: center;
    width: 48%;
    height: 210px;
    border-radius: 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 40px 20px rgba(95, 95, 95, 0.04);
}
.mobiles_img_provider_card {
    margin-top: 20px;
}
.mobiles_img_provider_card img{
    width: 55px;
    height: 55px;
}
.mobiles_provider_card_text {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1px;
    color: #393939;
}
.mobiles_provider_cards_text {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    color: #393939;
}
.mobiles_provider_cards_txt {
    margin: 10px 5px 0;
}
/* ***移动端样式End*** */

/* ***PC端样式Start*** */
.bannerImg {
    width: 100%;
}
.bannerImg img {
    width: 100%;
    height: 100%;
}
.pioneer {
    width: 100%;
    height: 550px;
    background: #FCFCFC;
}
.pioneer_text {
    padding-top: 50px;
    font-size: 36px;
    text-align: center;
    letter-spacing: 3px;
    color: #333333;
}
.pioneer_card_frame {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 60%;
}
.pioneer_card {
    margin-top: 80px;
    text-align: center;
    width: 23%;
    height: 316px;
    border-radius: 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 40px 20px rgba(95, 95, 95, 0.04);
    transition-duration: 300ms;
}
.pioneer_card:hover {
    margin-top: 30px;
    transition-duration: 300ms;
}
.pioneer_card:hover .card_text{
    color: #3688a1;
}
.pioneer_card:hover .card_line{
    width: 82%;
    transition-duration: 300ms;
}
.card_image {
    margin-top: 30px;
}
.card_image img {
    width: 88px;
    height: 88px;
}
.card_text {
    margin-top: 20px;
    font-size: 32px;
    text-align: center;
    letter-spacing: 2px;
    color: #333333;
}
.card_txt {
    width: 72%;
    height: 50px;
    font-size: 14px;
    margin: 20px auto 0;
}
.card_line {
    margin: 24px;
    width: 56px;
    height: 2px;
    background: #3688a1;
    transition-duration: 300ms;
}
.serviceProvider {
    width: 100%;
    /* height: 550px; */
    text-align: center;
    border-bottom: 1px solid #f6f6f6;
}
.serviceProvider_card_frame {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px auto;
    width: 60%;
}
.serviceProvider_card {
    text-align: center;
    width: 23%;
    height: 320px;
    border-radius: 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 40px 20px rgba(95, 95, 95, 0.04);
    transition-duration: 300ms;
}
.serviceProvider_img_card {
    margin-top: 30px;
}
.serviceProvider_img_card img {
    width: 120px;
    height: 120px;
}
.serviceProvider_card_text {
    margin-top: 20px;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 1px;
    color: #393939;
}
.serviceProvider_cards_text{
    font-size: 16px;
    text-align: center;
    line-height: 28px;
    letter-spacing: 1px;
    color: #393939;
}
.serviceProvider_cards_txt {
    margin: 16px 20px 0 20px;
}
/* ***PC端样式End*** */
</style>
