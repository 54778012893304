<template>
    <div>
        <Header :nowActive = "nowActive"  v-if="isMobile==false"></Header>
        <!-- PC端 -->
        <div v-if="isMobile == false">
            <div class="bannerImg">
                <img src="../assets/img/topbanner.svg" />
            </div>
            <div class="interact" @click="cancelBtn" style="border-bottom:none">
                <div class="interact_text">
                    <p class="interact_text_p">服务亮点</p>
                    <span class="interact_text_span">为您带来更便捷的物流配送场景</span>
                </div>
                <div class="operation uppart">
                    <div class="operation_card" >
                        <div class="card_image uppart">
                            <img src="../assets/img/lipic3.svg" alt="">
                        </div>
                        <div class="card_text">安全便捷</div>
                        <div class="card_txt toppart">在线接口调试，支持多种语言，便捷接入，授权，用户数据加密，安全可靠</div>
                    </div>
                    <div class="operation_card">
                        <div class="card_image">
                            <img src="../assets/img/lipic2.svg" alt="">
                        </div>
                        <div class="card_text border">优质服务</div>
                        <div class="card_txt toppart">一对一咨询，7x24小时服务，随叫随到。标准化需求管理，高效定制，快速落地</div>
                    </div>
                    <div class="operation_card" >
                        <div class="card_image">
                            <img src="../assets/img/lipic1.svg" alt="">
                        </div>
                        <div class="card_text">稳定可靠</div>
                        <div class="card_txt toppart">日均百万级API调用次数，延迟低于100ms，系统稳定性99.99%</div>
                    </div>
                    
                </div>
            </div>
			<div class="interact" @click="cancelBtn">
			    <div class="interact_text">
			        <p class="interact_text_p">业务合作</p>
			        <span class="interact_text_span">请根据您的经营属性选择相应的身份进行入驻</span>
			    </div>
			    <div class="operation hoverd">
			        <div class="operation_card" id="become">
			            <div class="card_image">
			                <img src="../assets/img/sj.svg" alt="">
			            </div>
			            <div class="card_text">商家服务型</div>
			            <div class="card_txt">面向商家，提供标准化的应用及高效便捷服务</div>
			            <div class="card_button" @click.stop="becomeMerchantBtn()"><span>成为商家</span></div>
			        </div>
			        <div class="operations_card" id="qrCode">
			            <div class="qr_image">
							<img src="../assets/img/Appletsrcode.png" alt="">
			            </div>
			            <div class="card_text">快小象小程序</div>
			            <div class="card_txt">扫描上方二维码了解相关内容</div>
			        </div>
			        <div class="operation_card">
			            <div class="card_image">
			                <img src="../assets/img/fws.svg" alt="">
			            </div>
			            <div class="card_text">品牌服务型</div>
			            <div class="card_txt">多品牌服务商且有独立的技术团队，可对接自己的后台系统</div>
			            <div class="card_button" @click="submitApplicationBtn"><span>提交申请</span></div>
			        </div>
			        <div class="operation_card" id="customized">
			            <div class="card_image">
			                <img src="../assets/img/djdz.svg" alt="">
			            </div>
			            <div class="card_text">独家定制型</div>
			            <div class="card_txt">面向独立开发的商家，提供定制化接口及特殊行业服务</div>
			            <div class="card_button" @click.stop="searchDemandBtn"><span>定制咨询</span></div>
			        </div>
			        <div class="operations_card" id="demand">
			            <div class="demand_text">
			                <div class="text_txt">市场联系人</div>
			                <div>联系电话：17180130034</div>
			                <div>联系邮箱：</div>
			                <div>maxiaohui@kuaixiaoxiang.com</div>
			            </div>
			            <div class="demand_text">
			                <div class="text_txt">技术联系人</div>
			                <div>联系电话：16710834636</div>
			                <div>联系邮箱：</div>
			                <div>yanxu@kuaixiaoxiang.com</div>
			            </div>
			        </div>
			    </div>
			</div>
        </div>
		
        <!-- 移动端 -->
        <div v-else>
            <div class="bannerImg">
                <img src="../assets/img/kfptsBanner.svg" />
            </div>
            <div class="mobiles_interact" @click="cancelBtn">
                <div class="mobiles_interact_text">
                    <p class="mobiles_interact_text_p">业务合作</p>
                    <span class="mobiles_interact_text_span">为您带来更便捷的物流配送场景</span>
                </div>
                <div class="mobiles_operation">
                    <div class="mobiles_operation_card" id="become">
                        <div class="mobiles_card_image">
                            <img src="../assets/img/sj.svg" alt="">
                        </div>
                        <div class="card_text">商家服务型</div>
                        <div class="card_txt">面向商家，提供标准化的应用及高效便捷服务</div>
                        <div class="card_button" @click.stop="becomeMerchantBtn()"><span>成为商家</span></div>
                    </div>
                    <div class="mobiles_operation_card" id="qrCode">
                        <div class="qr_card_image">
                            <img src="../assets/img/Appletsrcode.png" alt="">
                        </div>
                        <div class="card_text">快小象小程序</div>
                        <div class="card_txt">扫描上方二维码了解相关内容</div>
                    </div>
                    <div class="mobiles_operation_card">
                        <div class="mobiles_card_image">
                            <img src="../assets/img/fws.svg" alt="">
                        </div>
                        <div class="card_text">品牌服务型</div>
                        <div class="card_txt">多品牌服务商且有独立的技术团队，可对接自己的后台系统</div>
                        <div class="card_button" @click="submitApplicationMobileBtn"><span>提交申请</span></div>
                    </div>
                    <div class="mobiles_operation_card" id="customized">
                        <div class="mobiles_card_image">
							<img src="../assets/img/djdz.svg" alt="">
                        </div>
                        <div class="card_text">独家定制型</div>
                        <div class="card_txt">面向独立开发的商家，提供定制化接口及特殊行业服务</div>
                        <div class="card_button" @click.stop="searchDemandBtn"><span>定制咨询</span></div>
                    </div>
                    <div class="mobiles_operation_card" id="demand">
                        <div class="demand_mobiles_text">
                            <div class="text_txt">市场联系人</div>
                            <div>联系电话：17180130034</div>
                            <div>联系邮箱：</div>
                            <div>maxiaohui@kuaixiaoxiang.com</div>
                        </div>
                        <div class="demand_mobiles_text">
                            <div class="text_txt">技术联系人</div>
                            <div>联系电话：16710834636</div>
                            <div>联系邮箱：</div>
                            <div>yanxu@kuaixiaoxiang.com</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Publicfooter v-if="isMobile==false"></Publicfooter>
        <a-modal v-model="visiblePc" width="22%">
            <a-form-model ref="ruleForm" :model="ruleFormData" :rules="rules" layout="vertical">
                <a-form-model-item label="手机号：" prop="phone">
                    <a-input v-model="ruleFormData.phone" placeholder="请输入手机号" />
                </a-form-model-item>
                <a-form-model-item label="姓名：" prop="name">
                    <a-input v-model="ruleFormData.name" placeholder="请输入姓名" />
                </a-form-model-item>
                <a-form-model-item label="公司名称：" prop="companyName">
                    <a-input v-model="ruleFormData.companyName" placeholder="请输入公司名称" />
                </a-form-model-item>
                <a-form-model-item label="品牌名称：" prop="entryName">
                    <a-input v-model="ruleFormData.entryName" placeholder="请输入品牌名称" />
                </a-form-model-item>
                <a-form-model-item label="品类、门店数介绍：" prop="projectIntroduction">
                    <a-textarea v-model="ruleFormData.projectIntroduction"  placeholder="请输入品类、门店数介绍" :auto-size="{ minRows: 3, maxRows: 5 }" />
                </a-form-model-item>
                <a-form-model-item>
                    <div class="bottom_botton">
                        <div class="submit_botton" @click="submitForm"><span>立即提交</span></div>
                        <div class="reset_botton" @click="resetForm"><span>取消</span></div>
                    </div>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
        <a-modal v-model="visibleMobile" width="">
            <a-form-model ref="ruleForm" :model="ruleFormData" :rules="rules" layout="vertical">
                <a-form-model-item label="手机号：" prop="phone">
                    <a-input v-model="ruleFormData.phone" placeholder="请输入手机号" />
                </a-form-model-item>
                <a-form-model-item label="姓名：" prop="name">
                    <a-input v-model="ruleFormData.name" placeholder="请输入姓名" />
                </a-form-model-item>
                <a-form-model-item label="公司名称：" prop="companyName">
                    <a-input v-model="ruleFormData.companyName" placeholder="请输入公司名称" />
                </a-form-model-item>
                <a-form-model-item label="品牌名称：" prop="entryName">
                    <a-input v-model="ruleFormData.entryName" placeholder="请输入品牌名称" />
                </a-form-model-item>
                <a-form-model-item label="品类、门店数介绍：" prop="projectIntroduction">
                    <a-textarea v-model="ruleFormData.projectIntroduction"  placeholder="请输入品类、门店数介绍" :auto-size="{ minRows: 3, maxRows: 5 }" />
                </a-form-model-item>
                <a-form-model-item>
                    <div class="bottom_botton">
                        <div class="submit_mobile_botton" @click="submitForm"><span>立即提交</span></div>
                        <div class="reset_mobile_botton" @click="resetFormMobile"><span>取消</span></div>
                    </div>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import Header from '../components/header'
import Publicfooter  from '../components/footer'
export default {
    data () {
        return {
            visiblePc: false,
            visibleMobile: false,
            nowActive: ["open"],
            isMobile: false,
            ruleFormData: {
                phone: '',
                name: '',
                companyName: '',
                entryName: '',
                projectIntroduction: '',
            },
            rules: {
                phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
                name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
                entryName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
                projectIntroduction: [{ required: true, message: '请输入项目介绍', trigger: 'blur' }],
            },
        }
    },
    components:{
        Header,
        Publicfooter
    },
    mounted() {
        if (this._isMobile()) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }
    },
    methods: {
        _isMobile() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            return flag;
        },
        cancelBtn() {
            // 显示自主商家框样式
            let become = document.getElementById("become");
            become.style.display = "block";
            // 隐藏下载送APP框样式
            let qrCode = document.getElementById("qrCode");
            qrCode.style.display = "none";
            // 显示独家定制型框样式
            let customized = document.getElementById("customized");
            customized.style.display = "block";
            // 隐藏联系人框样式
            let demand = document.getElementById("demand");
            demand.style.display = "none";
        },
        // 成为商家按钮事件
        becomeMerchantBtn() {
            // 隐藏自主商家框样式
            let become = document.getElementById("become");
            become.style.display = "none";
            // 显示下载送APP框样式
            let qrCode = document.getElementById("qrCode");
            qrCode.style.display = "block";
            // 显示独家定制型框样式
            let customized = document.getElementById("customized");
            customized.style.display = "block";
            // 隐藏联系人框样式
            let demand = document.getElementById("demand");
            demand.style.display = "none";
        },
        // 定制咨询按钮事件
        searchDemandBtn() {
            // 隐藏独家定制型框样式
            let customized = document.getElementById("customized");
            customized.style.display = "none";
            // 显示联系人框样式
            let demand = document.getElementById("demand");
            demand.style.display = "block";
            // 显示自主商家框样式
            let become = document.getElementById("become");
            become.style.display = "block";
            // 隐藏下载送APP框样式
            let qrCode = document.getElementById("qrCode");
            qrCode.style.display = "none";
        },
        // PC端提交申请按钮事件
        submitApplicationBtn() {
            this.visiblePc = true;
        },
        // 移动端提交申请按钮事件
        submitApplicationMobileBtn() {
            this.visibleMobile = true;
        },
        // 立即提交按钮事件
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    // alert('submit!');
                }else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        // 取消按钮事件
        resetForm() {
            this.visiblePc = false;
            this.$refs.ruleForm.resetFields();
        },
        // 移动端取消按钮事件
        resetFormMobile() {
            this.visibleMobile = false;
            this.$refs.ruleForm.resetFields();
        },
    },
}
</script>

<style scoped>
/* ***移动端样式Start*** */
.mobiles_interact {
    background-color: #f9f9f9;
}
.mobiles_interact_text {
    padding-top: 30px;
    text-align: center;
}
.mobiles_interact_text_p {
    font-size: 30px;
    text-align: center;
    letter-spacing: 3px;
    color: #333333;
}
.mobiles_interact_text_span {
    font-size: 15px;
    text-align: center;
    letter-spacing: 2px;
    color: #B7B7B7;
}
.mobiles_operation {
    margin: 0 10%;
    padding-bottom: 10%;
}
.mobiles_operation_card {
    text-align: center;
    height: 400px;
    border-radius: 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 40px 20px rgba(95, 95, 95, 0.04);
    margin-top: 30px;
}
.mobiles_card_image {
    padding-top: 30px;
}
.qr_card_image {
    margin-top: 30px;
    padding-top: 30px;
}
.qr_card_image .uppart{
       margin-bottom: 52px;
}
.qr_card_image img {
    width: 200px;
    height: 200px;
}
.qr_card_image.uppart img {
    width: 80.56px;
    height: 79.17px;
}
.demand_mobiles_text {
    font-size: 16px;
    text-align: center;
    letter-spacing: 1px;
    color: #393939;
    padding-top: 48px;
}
.submit_mobile_botton {
    width: 110px;
    height: 40px;
    text-align: center;
    border-radius: 4px;
    background: #3688A1;
}
.submit_mobile_botton span {
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 1.6px;
    color: #FFFFFF;
}
.reset_mobile_botton {
    width: 110px;
    height: 40px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #3688A1;
}
.reset_mobile_botton span {
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 1.6px;
    color: #3688A1;
}
/* ***移动端样式End*** */
/* ***PC端样式Start*** */
.bannerImg {
    width: 100%;
}
.bannerImg img {
    width: 100%;
    height: 100%;
}
.interact {
    text-align: center;
    border-bottom: 1px solid #f6f6f6;
}
.interact_text {
    margin-top: 36px;
}
.interact_text_p {
    font-size: 36px;
    text-align: center;
    letter-spacing: 3px;
    color: #333333;
}
.interact_text_span {
    font-size: 16px;
    text-align: center;
    letter-spacing: 2px;
    color: #B7B7B7;
}
.operation {
    display: flex;
    justify-content: space-between;
    margin: 40px auto;
    width: 960px;
}
.operation.uppart .operation_card{
    background: none;
	box-shadow: none;
}
.operation.hoverd .operation_card:hover{
	border: 1px solid #3688A1;
	box-shadow: 0px 2px 40px 20px rgba(95, 95, 95, 0.04);
}
.operation.uppart .operation_card .card_image{
	margin-bottom: 52px;
}
.operation.uppart .operation_card .card_text{
	margin-bottom: 26px;
}
.operation.uppart .operation_card .card_text.border{
	border-right: 1px solid;
	border-left: 1px solid;
}

#qrCode {
    display: none;
}
#demand {
    display: none;
}
.operation_card {
    text-align: center;
    /* width: 32%; */
    width: 300px;
    height: 400px;
    border-radius: 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 40px 20px rgba(95, 95, 95, 0.04);
}
.operations_card {
    text-align: center;
    /* width: 32%; */
    width: 300px;
    height: 400px;
    border-radius: 16px;
    background: #FFFFFF;
    border: 1px solid #3688A1;
    box-shadow: 0px 2px 40px 20px rgba(95, 95, 95, 0.04);
}
.card_image {
    margin-top: 30px;
}
.card_image img {
    width: 110px;
    height: 110px;
}
.qr_image {
    margin: 30px 0;
}
.qr_image img {
    width: 180px;
    height: 180px;
}
.card_text {
    margin-top: 20px;
    font-size: 32px;
    text-align: center;
    letter-spacing: 2px;
    color: #333333;
}
.card_txt {
    width: 72%;
    font-size: 14px;
    margin: 20px auto 0;
}
.card_txt.toppart{
	width: 85%;
	
}
.card_button {
    width: 160px;
    height: 56px;
    border-radius: 4px;
    background: #3688A1;
    margin: 30px auto 0;
    cursor: pointer;
}
.card_button span {
    line-height: 56px;
    font-size: 20px;
    text-align: center;
    letter-spacing: 2px;
    color: #FFFFFF;
}
.demand_text {
    font-size: 16px;
    text-align: center;
    letter-spacing: 1px;
    color: #393939;
    margin: 50px 0;
}
.text_txt {
    font-size: 28px;
    text-align: center;
    letter-spacing: 2px;
    color: #333333;
    margin-bottom: 10px;
}
.ant-modal-close-x {
    display: none;
}
.ant-modal-body {
    padding-right: 40px;
	padding-left: 40px;
	padding-top: 20px;
	padding-bottom: 1px;
    border-radius: 16px;
    background: #FFFFFF;
}
.ant-input:hover{
	border-color:#3688A1 ;
}
.ant-modal-footer {
    display: none;
}
.bottom_botton {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.submit_botton {
    width: 48%;
    height: 48px;
    text-align: center;
    border-radius: 4px;
    background: #3688A1;
}
.submit_botton span {
    font-size: 18px;
    line-height: 48px;
    letter-spacing: 1.6px;
    color: #FFFFFF;
}
.reset_botton {
    width: 48%;
    height: 48px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #3688A1;
}
.reset_botton span {
    font-size: 18px;
    line-height: 48px;
    letter-spacing: 1.6px;
    color: #3688A1;
}
::v-deep .ant-modal-content{
	width: 100%
}
/* ***PC端样式End*** */
</style>
