<!--
 * @Author: dyson daishuai2030@qq.com
 * @Date: 2023-11-20 17:40:48
 * @LastEditors: dyson daishuai2030@qq.com
 * @LastEditTime: 2023-11-20 18:30:56
 * @FilePath: \kxx-gw\src\views\404.vue
 * @Description: 
 * 
-->
<template>
 
</template>
<script>
export default {
  data(){
    return{
      nowActive: [2],
        screenWidth: document.body.clientWidth,     // 屏幕宽
        screenHeight: document.body.clientHeight + 'px',    // 屏幕高
        isMobile: false,
    }
  },

  mounted(){
    this.setUrl()
    
  },
  methods:{
    
    setUrl(){
      let UrlParams = {}
      console.log(location.href.includes('dyPrivacy.php'),'location.href.indexOf()')
      if(location.href.indexOf('dyPrivacy.php')){
      UrlParams = this.getUrlParams(location.href)
      console.log(UrlParams,'UrlParams')
      if(UrlParams.type&&UrlParams.type =='1'){
      location.href = '/androidPrivacy.html'
      }else if(UrlParams.type&&UrlParams.type =='2'){
      location.href = '/androidPrivacy2.html'
      }
      }
    },

        getUrlParams(url) {
	    // 通过 ? 分割获取后面的参数字符串
	    let urlStr = url.split('?')[1]
		console.log(urlStr,'getUrlParams');
	    // 创建空对象存储参数
		let obj = {};
		if(!urlStr) return '';
	    // 再通过 & 将每一个参数单独分割出来
		let paramsArr = urlStr.split('&')
		for(let i = 0,len = paramsArr.length;i < len;i++){
	        // 再通过 = 将每一个参数分割为 key:value 的形式
			let arr = paramsArr[i].split('=')
			obj[arr[0]] = arr[1];
		}
		return obj
	},

   
  
  }
}
</script>
<style scoped>
.enter{
  background: url('../assets/img/businness.png') no-repeat center;
  background-size: 100% 100%;
  width: 100%;
  /* height: 720px; */
}
.entermobile{
  background: url('../assets/img/businessmobile.png') no-repeat center;
  background-size: 100% 100%;
  width: 100%;
}
.enterBtn{
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  height: 85%;
  padding-top: 10%;
  margin-left: 50%;
  width:40%
}
</style>