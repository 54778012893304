/*
 * @Author: dyson daishuai2030@qq.com
 * @Date: 2023-09-05 17:18:10
 * @LastEditors: dyson daishuai2030@qq.com
 * @LastEditTime: 2024-04-30 17:46:10
 * @FilePath: \kxx-gw\public\http\http.js
 * @Description:
 *
 */
import axios from "axios";
import store from "storejs";
import { Dialog } from "vant";
import setStorage from "../../public/localStorage/storage.js";
// axios全局默认配置
axios.defaults.timeout = 10000; // 超时时间
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;

// 路由请求拦截    http request 拦截器
axios.interceptors.request.use(
  (config) => {
    let $token = setStorage.getItem("token") || "";
    // config.headers = config.headers || {};
    // if (!config.headers["Content-Type"]) {
    //   config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    // } else {
    //   config.headers["Content-Type"] = config.headers["Content-Type"];
    // }
    console.log(config.url, "config.url");
    if (
      config.url == "/community/Recharge/pay" ||
      config.url == "/community/Recharge/aliPay" ||
      config.url == "/api/Recharge/aliCheckOrder" ||
      config.url == "/api/Recharge/checkOrder"
    ) {
      config.headers = {
        "Content-Type": "application/json",
      };
    } else {
      config.headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };
    }

    // config.headers = {
    //   "Content-Type": "application/x-www-form-urlencoded",
    // };
    if ($token) {
      config.headers["X-Token"] = $token;
    }
    // else{
    //     config.headers["Access-Control-Allow-Headers", "*"]
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);
// 路由响应拦截   http response 拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.error_code != 0) {
    }
    return response;
  },
  (error) => {
    switch (error.response.status) {
      case 401:
        store.clear();
        window.location.href = "/#/login";
        break;
      case 403:
        Dialog.confirm({
          message: "禁止访问，请联系客服人员！",
        });
        break;
      case 405:
        Dialog.confirm({
          message: "访问被拒绝，请稍后访问！",
        });
        break;
      case 406:
        Dialog.confirm({
          message: "访问不接受，请联系客服人员！",
        });
        break;
      case 409:
        Dialog.confirm({
          message: "访问不接受，请联系客服人员！",
        });
        break;
      case 419:
        Dialog.confirm({
          message: "访问不接受，请联系客服人员！",
        });
        break;
      default:
        Dialog.confirm({
          message: "系统繁忙，请稍后再试！",
        });
    }
    return error;
  }
);
