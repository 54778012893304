<template>
  <!-- 愿景+品牌价值 -->
  <div>
    <!-- <Header :nowActive="nowActive" v-if="isMobile==false"></Header> -->
    <!-- <PublicBanner v-if="isMobile==false"></PublicBanner> -->
    <div style="width: 100%">
      <img style="width: 100%" src="../assets/img/brandValue.jpg" />
    </div>
    <!-- <Publicfooter  ref="footer" v-if="isMobile==false"></Publicfooter> -->
  </div>
</template>

<script>
import Header from "../components/header.vue";
import PublicBanner from "../components/banner.vue";
import Publicfooter from "../components/footer.vue";
export default {
  components: {
    Header,
    PublicBanner,
    Publicfooter,
  },
  data() {
    return {
      nowActive: ["brandValue"],
	  isMobile:false
    };
  },
  mounted(){
	  // this.isMobile = this.$refs.footer.isMobile;
  }
};
</script>

<style>
</style>
