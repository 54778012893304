<template>
  <!-- 发展历程 -->
  <div>
    <!-- <Header :nowActive="nowActive" v-if="!isMobile"></Header> -->
    <!-- <PublicBanner  v-if="!isMobile"></PublicBanner> -->
    <div style="width: 100%">
      <img style="width: 100%" src="../assets/img/developmentHistory.jpg" />
    </div>
    <!-- <Publicfooter ref="footer" v-if="!isMobile"></Publicfooter> -->
  </div>
</template>

<script>
import Header from "../components/header.vue";
import PublicBanner from "../components/banner.vue";
import Publicfooter from "../components/footer.vue";
export default {
  components: {
    Header,
    PublicBanner,
    Publicfooter,
  },
  data() {
    return {
      nowActive: ["developmentHistory"],
	  isMobile:false
    };
  },
  mounted(){
	  // this.isMobile = this.$refs.footer.isMobile;
  }
};
</script>

<style>
</style>
