<template>
  <div style="background:#fff;">
    <van-nav-bar title="充值"></van-nav-bar>
    <!-- <span class="rechargeNum" style="color:#777">充值账号:<span style="color:#000;font-size:1rem">{{rechargeNum}}</span></span> -->
    <div style="padding:1%;margin-top: 1rem;background">
      <p style="font-size: 0.7rem;padding-left:1%">选择支付方式：</p>
      <van-radio-group @change="paymentRadioChange" v-model="radio" direction="horizontal">
        <div class="wechatpay" ref="wechatpay">
          <p style="font-size: 0.8rem;color:#000"></p>
          <van-radio ref="wechatpayradio" checked-color="#1989fa" :name="2"></van-radio>
        </div>
        <!-- <div class="alipay" ref="alipay"  v-if="type!='xcx'">
          <p style="font-size: 0.8rem;color:#000"></p>
          <van-radio checked-color="#1989fa" :name="1"></van-radio>
        </div> -->

      </van-radio-group>
    </div>
    <div style="padding:1%;margin-top: 1rem;background">
      <p style="font-size: 0.7rem;padding-left:1%">选择支付金额：</p>
      <van-radio-group @change="moneyRadioChange" v-model="moneyRadio" direction="horizontal">
        <div ref="moneycont" v-for="item in rechargeMoneyList" class="rechargeMoneyCont">
          <span style="padding-left:0.75rem;color:#555">{{ item.name }}</span>
          <van-radio checked-color="#1989fa" :name="item.value"></van-radio>
        </div>
      </van-radio-group>
      <van-cell-group style="margin-top: 1rem">
        <van-field type='number' @input='moneyInputChange' clearable v-model="money" label="其他金额" placeholder="其他金额" />
      </van-cell-group>
      <div style="text-align:center;margin-top: 1rem">
        <van-button @click="recharge" style="width: 80%" type="info">立即充值</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import store from 'storejs'
import setStorage from '../../public/localStorage/storage.js'
import qs from 'qs'
export default {
  data() {
    return {
      radio: 0,
      moneyRadio: 0,
      rechargeNum: '',
      rechargeMoneyList: [
        {
          name: '100元',
          value: 100
        },
        {
          name: '200元',
          value: 200
        },
        {
          name: '300元',
          value: 300
        },
        {
          name: '500元',
          value: 500
        }
      ],
      money: '',
      ip: '',
      tokenStr: '',
    }
  },
  mounted() {
    let token = this.$route.query.t
    this.tokenStr = this.$route.query.t
    // alert(this.type)
    setStorage.setItem({ value: token, name: 'token', expires: 604800000 })
  },
  methods: {
    onBack() {
      this.$router.push('/pocket')
    },
    // 选择支付金额
    moneyRadioChange(value) {
      this.money = value
      for (let i in this.rechargeMoneyList) {
        if (this.rechargeMoneyList[i].value == value) {
          this.$refs.moneycont[i].style.backgroundColor = 'rgba(25,137,250,0.7)'
          this.$refs.moneycont[i].children[0].style.color = '#fff'
        } else {
          this.$refs.moneycont[i].style.backgroundColor = '#fff'
          this.$refs.moneycont[i].children[0].style.color = '#555'
        }
      }
    },
    // 手动输入充值金额
    moneyInputChange(value) {
      this.money = value.replace(/^(0+)|[^\d]+/g,'')
      if (value == '') {
        this.moneyRadio = ''
      }
    },
    // 选择支付方式 1:支付宝,2:微信
    paymentRadioChange(value) {
      this.radio = value;
      if (value == 1) {
        this.$refs.alipay.style.border = '1px solid #27afff'
        this.$refs.wechatpay.style.border = 'none'
      } else {
        this.$refs.wechatpay.style.border = '1px solid #04BE02'
        this.$refs.alipay.style.border = 'none'
        this.$refs.wechatpayradio.iconStyle.backgroundColor = '#04BE02'
        this.$refs.wechatpayradio.iconStyle.borderColor = '#04BE02'
      }
    },
    // 立即充值按钮
    recharge() {
      if(this.radio == 1){
        if (this.money == '') {
          this.$toast('请选择充值金额')
        }
        else if (this.money < 1) {
          this.$toast('充值金额不得少于1元')
        }
        else {
          this.$axios.post(
            this.$api.server_api.v1.ALIPAY,
            qs.stringify({
              pay_type: 1,
              price: this.money,
            }),
            { headers: { authorization: `Bearer ${this.tokenStr}` }, }
          ).then(res => {
            this.$router.push({name:'alipay',params:{data:res.data.data}});
          })
        }
      }else if(this.radio == 2){
        if (this.money == '') {
          this.$toast('请选择充值金额')
        }
        else if (this.money < 1) {
          this.$toast('充值金额不得少于1元')
        }
        else {
          this.$axios.post(
            this.$api.server_api.v1.RCHARGE,
            {
              pay_type: 1,
              price: this.money,
            },
            { headers: { authorization: `Bearer ${this.tokenStr}` }, }
          ).then(res => {
            window.location.href = res.data.data
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.alipay {
  width: 45%;
  height: 5rem;
  background-size: 100% 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-left: 0.5rem;
  background: url('../assets/img/alipay.png') no-repeat center;
  background-size: 100% 100%;
  padding-top: 0.5rem;
  /* border: 1px solid #27afff; */
  margin-left: 1%;
}

.wechatpay {
  width: 45%;
  height: 5rem;
  background-size: 100% 150% !important;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-left: 0.5rem;
  background: url('../assets/img/wechatpay.jpeg') no-repeat center;
  /* border: 1px solid #04BE02; */
  padding-top: 0.5rem;
  margin-left: 1%
}

.rechargeNum {
  background: #fff;
  width: 100%;
  height: 2rem;
  display: inline-block;
  line-height: 2rem;
  font-size: 0.8rem;
  padding-left: 0.5rem;
}

.rechargeMoneyCont {
  width: 46%;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  /* padding-left: 0.5rem; */
  background-size: 100% 100%;
  /* padding-top: 0.5rem; */
  align-items: center;
  margin-left: 1%;
  border: 1px solid #1989fa;
  background: #fff;
  border-radius: 0.5rem;
  margin-left: 2%;
  color: #1989fa
}

.rechargeMoneyCont:nth-of-type(3),
.rechargeMoneyCont:nth-of-type(4) {
  margin-top: 1%;

}
</style>  