<template>
  <div>
    <a-affix :offset-top="top">
      <div
        style="
          display: flex;
          align-items: space-between;
          background: #fff;
          cursor: pointer;
          z-index: 10;
          width: 100%;
          height: 60px;
          align-items: center;
        "
      >
        <div @click="clickLogo" style="width: 50%; margin-left: 5%">
          <img
            style="width: 110px"
            src="../assets/img/logo.svg"
            alt=""
            srcset=""
          />
          <!-- <span style="font-size: 20px;color:#2f70dc;line-height:10px;padding-top:20px">北京欧信软件服务有限公司</span> -->
        </div>
        <!-- ***************隐藏导航模式************** -->
        <a-menu
          v-if="!isMobile"
          popupClassName="childreItem"
          theme="light"
          @click="handleClick"
          v-model="current"
          style="
            align-items: right;
            margin-left: 10%;
            width: 50%;
            background: none;
            line-height: normal;
            font-weight: bold;
            font-size: 16px;
          "
          mode="horizontal"
        >
          <a-sub-menu key="index" @titleClick="titleClick">
            <span slot="title">首页</span>
            <!-- <a-menu-item-group>
              <a-menu-item key="businessScenario">业务场景</a-menu-item>
              <a-menu-item key="companyProfile">公司简介</a-menu-item>
            </a-menu-item-group> -->
          </a-sub-menu>
          <!-- <a-sub-menu key="functionIntroduction">
            <span slot="title">产品服务</span>
           a-menu-item-group>
              <a-menu-item key="functionIntroduction">功能介绍</a-menu-item>
              <a-menu-item key="productService">产品服务</a-menu-item>
            </a-menu-item-group> -->
          <!-- </a-sub-menu> -->
          <a-menu-item key="functionIntroduction">产品服务</a-menu-item>
          <a-menu-item key="downloadCenter">下载中心</a-menu-item>
          <!-- <a-menu-item key="kxxNews">快小象新闻</a-menu-item> -->
          <a-menu-item key="aboutKxx">关于我们</a-menu-item>
          <!-- <a-sub-menu  key="aboutKxx">
            <span slot="title">关于我们</span>
            <a-menu-item-group>
              <a-menu-item key="aboutKxx">快小象简介</a-menu-item>
              <a-menu-item key="developmentHistory">发展历程</a-menu-item>
              <a-menu-item key="brandValue">价值愿景</a-menu-item>
            </a-menu-item-group> -->
          <!-- </a-sub-menu> -->
          <a-menu-item key="businessServer">商户入口</a-menu-item>
          <a-menu-item key="open">开放平台</a-menu-item>
          <!-- <a-menu-item key="serviceProviderJoining">服务商加盟</a-menu-item> -->
          <a-menu-item key="userManual">使用教程</a-menu-item>

          <a-menu-item key="paidArticles">付费文章</a-menu-item>
        </a-menu>
        <div
          v-else
          style="
            width: 50%;
            background: none;
            line-height: normal;
            font-weight: bold;
            font-size: 15px;
          "
        >
          <div style="position: relative; display: flex">
            <div
              slot="title"
              class="top-tab"
              style="margin-right: 20px; font-size: 12px"
              @click="newclick(1)"
            >
              {{ menuItem[changeIndex].name }}
            </div>
            <div
              slot="title"
              class="top-tab"
              style="
                font-size: 12px;
                background: #3688a1;
                color: #fff;
                float: right;
              "
              @click="newclick(2)"
            >
              商户入口
            </div>
            <ul class="top-tab-child" v-show="visibility">
              <li
                v-for="(item, index) in menuItem"
                :key="index"
                :class="[{ select: index == changeIndex }, item.indx]"
                @click="tabclick(item, index)"
                ref="item.indx"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
          <!-- <a-menu-item key="serviceProviderJoining">服务商加盟</a-menu-item> -->
        </div>
        <!-- <a-menu popupClassName="childreItem" theme="light" v-model="current" style="align-items:right;margin-left: 30%;width:50%;background:none;line-height:normal;font-weight: bold;" mode="horizontal">
        <a-menu-item v-for="item in navList" @click="jumpPage(item.url)"  ref="indexItem" :key="item.sort">{{item.nav_name}}</a-menu-item> -->
        <!-- <a-menu-item @click="goPersonalEntrance" key="user">普通用户入口</a-menu-item>
        <a-menu-item key="business">外卖商户入口</a-menu-item>
        <a-menu-item @click="goDownloadCenter" key="download">下载中心</a-menu-item>
        <a-menu-item @click="goCityPartner" key="city">城市合伙人</a-menu-item>
        <a-menu-item key="open">开放平台</a-menu-item> -->
        <!-- </a-menu> -->
        <!-- <div style="display:flex;align-items:center;justify-content:space-around;width:20%;color:rgba(255, 255, 255, 0.65)">
        <p @click=" visible = true"> <icon-font type="icon-location" />   {{locationCity}}</p>
        <p> <span style="cursor:pointer"  @click="goReg">登录</span>/<span @click="goLogin" style="cursor:pointer">注册</span></p>
        <p><a-button @click="goAdminPage" type="primary">运营商后台</a-button></p>
      </div> -->
      </div>
    </a-affix>
    <!-- <div>
    <a-modal v-model="visible" width="60%" title="请选择您所在的城市">
      <p style="font-size: 1rem">当前定位城市：{{locationCity}}</p>
      <v-distpicker style="margin-top: 10px" ></v-distpicker>
    </a-modal> 
  </div> -->
  </div>
</template>
<script>
import { Icon } from "ant-design-vue";
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: require("../assets/js/iconfont.js"),
});
export default {
  data() {
    return {
      current: [],
      selectcurrent: null,
      top: 0,
      navList: [],
      locationCity: "",
      space: "&ensp;",
      visible: false,
      webLogo: "",
      isMobile: false,
      menuItem: [
        { name: "主页", indx: "longpage" },
        { name: "业务场景", indx: "block1" },
        { name: "功能介绍", indx: "block2" },
        { name: "产品服务", indx: "block3" },
        { name: "下载中心", indx: "block4" },
        { name: "快小象简介", indx: "block5" },
        { name: "发展历程", indx: "block6" },
        { name: "价值愿景", indx: "block7" },
        { name: "开放平台", indx: "block8" },
        { name: "使用教程", indx: "block9" },
      ],
      visibility: false,
    };
  },
  props: ["nowActive", "activeindex"],
  computed: {
    changeIndex() {
      return (this.selectcurrent = this.activeindex);
    },
  },
  watch: {
    nowActive(newVal) {},
    activeindex(newVal) {
      this.activeindex = newVal;
    },
    deep: true,
  },
  components: {
    IconFont,
  },
  mounted: function () {
    this.current = this.nowActive;
    this.getNavList();
    this.getLocation();
    this.getBasicInfo();
    if (this._isMobile()) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  methods: {
    tabclick(e, index) {
      let propval = {
        name: e.name,
        tabNum: e.indx,
        index: index,
      };
      console.log(propval, "propval");
      this.$emit("scrollTopto", propval);
      this.visibility = !this.visibility;
    },
    newclick(e) {
      if (e == 1) {
        this.visibility = !this.visibility;
      } else {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // android终端
        let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
        if (isAndroid) {
          console.log("android终端");
          window.open(
            "https://kxx-apk.oss-cn-hangzhou.aliyuncs.com/kuaixiaoxiang.apk",
            "_blank"
          );
        } else if (isiOS) {
          console.log("ios终端");
          window.open(
            "https://apps.apple.com/app/%E5%BF%AB%E5%B0%8F%E8%B1%A1/id1642466933",
            "_blank"
          );
        }
      }
    },
    handleClick(e) {
      if (e.key == "businessScenario") {
        this.$router.push("/businessScenario");
      } else if (e.key == "companyProfile") {
        this.$router.push("/companyProfile");
      } else if (e.key == "functionIntroduction") {
        this.$router.push("/functionIntroduction");
      } else if (e.key == "productService") {
        this.$router.push("/productService");
      } else if (e.key == "downloadCenter") {
        this.$router.push("/downloadCenter");
      } else if (e.key == "kxxNews") {
        this.$router.push("/kxxNews");
      } else if (e.key == "aboutKxx") {
        this.$router.push("/aboutKxx");
      } else if (e.key == "developmentHistory") {
        this.$router.push("/developmentHistory");
      } else if (e.key == "brandValue") {
        this.$router.push("/brandValue");
      } else if (e.key == "businessServer") {
        window.open("https://kxx.kuaixiaoxiang.com/", "_blank");
      } else if (e.key == "open") {
        this.$router.push("/openPlatform");
      } else if (e.key == "serviceProviderJoining") {
        this.$router.push("/serviceProviderJoining");
      } else if (e.key == "paidArticles") {
        this.$router.push("/paidArticles");
      } else if (e.key == "userManual") {
        this.$router.push("/userManual");
      }
    },
    titleClick(e) {
      if (e.key == "index") {
        this.$router.push("/");
      }
    },
    goIndex() {
      this.$router.push("/");
    },
    goCityPartner() {
      this.$router.push("/cityPartner");
    },
    goPersonalEntrance() {
      this.$router.push("/personalEntrance");
    },
    goDownloadCenter() {
      this.$router.push("/downloadCenter");
    },
    getNavList() {
      this.$axios.post(this.$api.server_api.v1.NAV_LIST).then((res) => {
        this.navList = res.data.data;
      });
    },
    clickLogo() {
      this.$router.push("/");
    },
    goAdminPage() {
      window.open("http://" + this.navList[6].url);
    },
    goReg() {
      window.open("http://yxd.solflower.cn/admin/login/index.html");
    },
    goLogin() {
      window.open("http://yxd.solflower.cn/home/index/reg");
    },
    jumpPage(url) {
      if (url == "/open") {
        this.$message.warning("正在开放中");
      } else if (url == "/businessServer") {
        // window.location.href ='https://kxx.kuaixiaoxiang.com/'
        window.open("https://kxx.kuaixiaoxiang.com/", "_blank");
      } else {
        this.$router.push(url);
      }
    },
    getLocation() {
      this.$axios
        .get(
          "https://restapi.amap.com/v3/ip?key=d0d8b6db70356b8981f4cea5a81c0a35&output=json"
        )
        .then((res) => {
          this.locationCity = res.data.city;
        });
    },
    getBasicInfo() {
      this.$axios
        .post(this.$api.server_api.v1.FOOTER_BASIC_INFO)
        .then((res) => {
          this.webLogo = res.data.data.web_logo;
        });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style scoped>
.ant-modal-header {
  background: #1890ff !important;
}

.ant-modal-title {
  color: #fff !important;
}

.ant-menu-item-selected {
  color: #fff !important;
  background: #3688a1 !important;
  border-radius: 5px !important;
  border-bottom: none !important;
  font-weight: bold;
}

.ant-menu-item {
  height: 30px;
  line-height: 30px;
  margin-right: 14px;
  border-radius: 5px !important;
  border-bottom: none !important;
}

.ant-menu-horizontal {
  border: none !important;
}

.ant-menu-item:hover {
  background: #3688a1 !important;
  color: #fff !important;
  border-radius: 5px !important;
  border-bottom: none !important;
  font-weight: bold;
}

.ant-menu-submenu {
  margin-right: 14px;
}

.ant-menu-submenu .ant-menu-submenu-horizontal {
  border-bottom: none !important;
}

.ant-menu-submenu .ant-menu-submenu-active {
  background: #3688a1 !important;
  color: #fff !important;
  border-radius: 5px !important;
  border-bottom: none !important;
  font-weight: bold;
}

/* 下拉菜单样式 */
.ant-menu-submenu {
  height: 30px !important;
  line-height: 30px !important;
  border-radius: 5px !important;
}

.ant-menu-submenu:hover {
  color: #fff !important;
  background: #3688a1 !important;
  border-radius: 5px !important;
  border-bottom: none !important;
  font-weight: bold;
}

.ant-menu-submenu-title:hover {
  color: #fff !important;
  border-bottom: none !important;
}

.submenu-title:hover {
  color: #fff !important;
}

/* 下拉菜单选中样式 */
.ant-menu-horizontal > .ant-menu-submenu-selected {
  background: #3688a1 !important;
  border-radius: 5px !important;
  border-bottom: none !important;
  font-weight: bold;
  color: #fff;
}

.ant-menu-horizontal > .ant-menu-submenu-selected .ant-menu-submenu-title {
  background: #3688a1 !important;
  border-radius: 5px !important;
  border-bottom: none !important;
  font-weight: bold;
  color: #fff;
}

.ant-menu-submenu-selected {
  color: #fff;
}

.ant-menu-horizontal {
  border-radius: 5px !important;
}

.ant-menu-submenu-popup ul {
  width: 104px !important;
}

.ant-menu-vertical.ant-menu-sub {
  width: 104px !important;
  padding: 0;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item {
  text-align: center;
  margin: 0;
  padding: 0;
}

.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #3688a1 !important;
}

/* 移动端菜单样式 */
.ant-menu-vertical > .ant-menu-item {
  height: 30px !important;
  line-height: 30px !important;
  padding: 0;
  margin: 0;
  text-align: center;
  width: 84px;
}

.ant-menu-item:hover {
  background: #3688a1 !important;
  color: #fff !important;
  border-radius: 5px !important;
  border-bottom: none !important;
  font-weight: bold;
}

.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 30px !important;
  line-height: 30px !important;
}

.top-tab {
  text-align: center;
  line-height: 35px;
  width: 84px;
  height: 35px;
  border-radius: 4px;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #3688a1;
  float: left;
  color: #3688a1;
}

.top-tab-child {
  position: absolute;
  left: 0;
  top: 45px;
  background: #fff;
  width: 84px;
  height: auto;
  z-index: 999;
}

.top-tab-child li {
  font-size: 13px;
  font-family: "PingFang SC";
  line-height: 35px;
  font-weight: initial;
  width: 84px;
  height: 35px;
  border-radius: 2px;
  text-align: center;
  color: #333333;
}

.top-tab-child li.select {
  background: rgba(54, 136, 161, 0.1);
  color: #3688a1;
}

::v-deep .ant-affix {
  z-index: 999;
}
</style>
