<template>
  <div>
    <van-loading size="24px" vertical>加载中...</van-loading>
    <iframe
      :srcdoc="alipayData"
      frameborder="no"
      border="0"
      marginwidth="0"
      marginheight="0"
      scrolling="no"
      width="100%"
      height="380"
      style="display: flex; align-items: center; overflow: hidden"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      alipayData: "", //支付宝支付参数
    };
  },
  created() {
    this.alipayData = this.$route.params.data.formData;
    console.log("支付参数", this.$route.params.data);
  },
};
</script>

<style></style>
