<template>
  <div>
    <Header :nowActive="nowActive" v-if="isMobile == false"></Header>

    <!-- PC端 -->
    <div class="downloadAppCont" v-if="isMobile == false">
      <div class="left-navigation">
        <div
          v-for="(item, index) in navItems"
          :key="index"
          @click="scrollToContent(index)"
          :class="{ active: currentIndex === index }"
        >
          {{ item }}
        </div>
      </div>

      <div class="right-content2" ref="rightContent">
        <div class="main-title">快小象商家版操作指引</div>
        <div
          class="main-margin"
          v-for="(item, index) in contentItems"
          :key="index"
          :ref="`content-${index}`"
        >
          <div class="secondary-title">{{ item.title }}</div>
          <img :src="item.image" alt="" />
          <img :src="item.image1" v-show="item.image1" />
          <div class="attention" v-show="item.content">{{ item.content }}</div>
          <img :src="item.image2" alt="" v-show="item.image2" />
          <!-- <div>{{ item.content }}</div> -->
        </div>
      </div>
    </div>
    <!--移动 -->
    <div v-else class="downloadAppMobileCont">
      <div class="main-title">快小象商家版操作指引</div>
      <div
        class="main-margin"
        v-for="(item, index) in contentItems"
        :key="index"
        :ref="`content-${index}`"
      >
        <div class="secondary-title">{{ item.title }}</div>
        <img :src="item.image" alt="" />
        <img :src="item.image1" v-show="item.image1" />
        <div class="attention" v-show="item.content">{{ item.content }}</div>
        <img :src="item.image2" alt="" v-show="item.image2" />
        <!-- <div>{{ item.content }}</div> -->
      </div>
    </div>
    <Publicfooter v-if="isMobile == false"></Publicfooter>
  </div>
</template>
<script>
import Header from "../components/header";
import Publicfooter from "../components/footer";
export default {
  data() {
    return {
      nowActive: ["userManual"],
      isMobile: false,
      navItems: [
        "商家注册",
        "实体门店创建",
        "添加线上门店",
        "绑定自主配送",
        "订单处理",
        "钱包充值",
        "联系客服",
      ],
      contentItems: [
        {
          title: "1、商家注册",
          image: require("@/assets/img/manual1.png"),
          content: "",
        },
        {
          title: "2、实体门店创建",
          image: require("@/assets/img/manual2.png"),
          content: "",
        },
        {
          title: "3、添加线上门店",
          image: require("@/assets/img/manual3.png"),
          image1: require("@/assets/img/manual3-1.png"),
          image2: require("@/assets/img/manual3-2.png"),
          content:
            "注：如美团门店显示已绑定，请勿解绑。请将门店ID及快小象注册手机号发给运营，运营联系快小象客服协助授权操作，或自主联系客服发送ID和快小象注册手机号进行绑定。",
        },
        {
          title: "4、绑定自主配送",
          image: require("@/assets/img/manual4.png"),
          content: "",
        },
        {
          title: "5、订单处理",
          image: require("@/assets/img/manual5.png"),
          content: "",
        },
        {
          title: "6、钱包充值",
          image: require("@/assets/img/manual6.png"),
          content: "",
        },
        {
          title: "7、联系客服",
          image: require("@/assets/img/manual7.png"),
          content: "",
        },
      ],
      currentIndex: 0,
      screenHeight: document.body.clientHeight + "px", // 屏幕高
    };
  },
  components: {
    Header,
    Publicfooter,
  },
  mounted() {
    if (this._isMobile()) {
      this.isMobile = true;
      // this.$router.replace('/m_index');
    } else {
      this.isMobile = false;
      console.log("啊数据库量很大顶顶顶顶哒哒哒哒哒哒哒哒哒");
      // this.$router.replace('/pc_index');
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    scrollToContent(index) {
      this.currentIndex = index;
      this.$nextTick(() => {
        const targetElement = this.$refs[`content-${index}`][0];
        this.$refs.rightContent.scrollTop = targetElement.offsetTop - 80;
      });
    },
  },
};
</script>

<style scoped>
.downloadAppCont {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #9a9a9a;
  font-size: 16px;
}
.left-navigation {
  width: 15%;
  margin-bottom: 50px;
  font-size: 16px;
  padding: 20px;
  /* background-color: #f5f5f5; */
  overflow-y: auto;
  position: sticky;
  top: 0;
  /* border-right: 1px solid #f5f5f5; */
}
.left-navigation div {
  cursor: pointer;
  padding: 10px 0;
}
.left-navigation div.active {
  color: #007bff;
  font-weight: bold;
}
img {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}
.right-content {
  width: 50%;
  margin-bottom: 50px;
  padding: 20px;
  overflow-y: auto;
  max-height: 80vh;
}
.right-content2 {
  width: 50%;
  margin-bottom: 50px;
  padding: 20px;
}
.main-title {
  font-size: 18px;
  text-align: center;
  margin: 20px 0;
}
.main-margin {
  margin: 20px 0;
}
.secondary-title {
  margin-bottom: 15px;
}
.attention {
  color: red;
  margin: 10px 0;
}
.downloadAppMobileCont {
  width: 100%;
  height: auto;
  /* background: url("../assets/img/445566.png") no-repeat center; */
  /* background-size: 100% 100%; */
  /* padding-top: 5%; */
  /* background-size: cover; */
  padding-top: 40px;
  padding: 20px;
}
</style>
