<template>
  <div>
    <Header :nowActive = "nowActive" v-if="isMobile==false"></Header>
    <!-- PC端 -->
    <div class="downloadAppCont" v-if="isMobile == false">
		<!-- <div class="topTitle">下载中心</div> -->
      <img style="width: 40%;margin-top:5%;margin-left:3%" src="../assets/img/phone.png" alt="">
      <div class="largeFrame">
        <div class="smallFrame">
          <!-- IOS -->
          <div class="smallFrame-img">
            <a :href="IOSUrl">
              <div class="exit_hover">
                <img class="exit1" src="../assets/img/app.png" />
                <img class="exit2" :src="IOSQR" />
              </div>
            </a>
            <div class="smallFrame-text">
              <p>IOS</p>
            </div>
          </div>
          <!-- Android -->
          <div class="smallFrame-imgs">
            <a :href="AndroidUrl">
              <div class="exit_hover">
                <img class="exit1" src="../assets/img/and.png" />
                <img class="exit2" :src="AndroidQR" />
              </div>
            </a>
            <div class="smallFrame-text">
              <p>Android</p>
            </div>
          </div>
          <!-- Windows -->
          <div class="smallFrame-imgs">
            <img src="../assets/img/win.png" alt="" srcset="">
            <div class="smallFrame-text">
              <p>Windows</p>
            </div>
          </div>
          <!-- 小程序 -->
          <div class="smallFrame-imgs">
            <div class="exit_hover">
              <img class="exit1" src="../assets/img/wx.png" />
              <img class="exit2" :src="miniProgram" />
            </div>
            <!-- <img src="../assets/img/wx.png" alt="" srcset=""> -->
            <div class="smallFrame-text">
              <p>小程序</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <p style="visibility:hidden">123</p>
      <div style="width:50%;margin-left:40%;text-align:center;margin-top:13%">
        <p style="font-size:32px;color:#fff;font-weight:500;text-align:center">
          聚合平台APP下载
        </p>
        <div style="display:flex;margin-top: 50px;justify-content:flex-start;">
          <div style="width:100%;">
            <img  style="width:60%" :src="AndroidQR" alt="" srcset="">
            <div style="text-align:center">
              <a-button size="large" type="primary" style="margin-top: 15px;background:#6c6dba;border-color:#6c6dba">
                <div style="display:flex;align-items:center;">
                  <a-avatar #icon  :size="20" color="#fff" :src="appleIcon"></a-avatar>
                  <p>IOS下载</p>
                </div>
              </a-button>
            </div>
          </div>
          <div style="width:100%">
            <img  style="width:60%" :src="AndroidQR" alt="" srcset="">
            <a-button size="large" type="primary" style="margin-top: 15px;;background:#6c6dba;border-color:#6c6dba">
              <div style="display:flex;align-items:center;">
                <a-avatar #icon  :size="20" color="#fff" :src="androidIcon"></a-avatar>
                <p>Android下载</p>
              </div>
            </a-button>
          </div>
          <div  style="width:100%">
            <img  style="width:60%" :src="miniProgram" alt="" srcset="">
            <a-button  type="primary" style="margin-top: 15px;background:#6c6dba;border-color:#6c6dba" size="large">
              <div style="display:flex;align-items:center;">
                <a-avatar #icon  :size="20" color="#fff" :src="minipro"></a-avatar>
                <p>微信小程序</p>
              </div>
            </a-button>
            <p style="color:#fff">无需下载扫码即用</p>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 移动端 -->
    <div v-else class="downloadAppMobileCont"  :style="{height:screenHeight}">
      <div class="appLargeFrame">
        <div style="display:flex;">
          <div class="appImg">
            <a :href="IOSUrl">
              <img src="../assets/img/app.png" class="appImg-img" />
            </a>
            <div class="appImg-text">
              <p>IOS</p>
            </div>
          </div>
          <!-- Android -->
          <div class="appImg">
            <a :href="AndroidUrl">
              <img src="../assets/img/and.png" class="appImg-img" />
            </a>
            <div class="appImg-text">
              <p>Android</p>
            </div>
          </div>
          <!-- Windows -->
          <div class="appImg">
            <img src="../assets/img/win.png" class="appImg-img" />
            <div class="appImg-text">
              <p>Windows</p>
            </div>
          </div>
          <!-- 小程序 -->
          <div class="appImg">
            <img :src="miniProgram" class="appImg-img" />
            <div class="appImg-text">
              <p>小程序</p>
            </div>
          </div>
        </div>
      </div>
        <!-- <div style="display:flex;justify-content:flex-start;text-align:center;margin-top:130%">
          <div style="width:100%;">
            <img  style="width:40%" :src="AndroidQR" alt="" srcset="">
            <div style="text-align:center">
              <a-button size="mini" type="primary" style="margin-top: 15px;background:#6c6dba;border-color:#6c6dba">
                <div style="display:flex;align-items:center;">
                  <a-avatar #icon  :size="20" color="#fff" :src="appleIcon"></a-avatar>
                  <p>IOS下载</p>
                </div>
              </a-button>
            </div>
          </div>
          <div style="width:100%">
            <img  style="width:40%" :src="AndroidQR" alt="" srcset="">
            <a-button size="mini" type="primary" style="margin-top: 15px;;background:#6c6dba;border-color:#6c6dba">
              <div style="display:flex;align-items:center;">
                <a-avatar #icon  :size="20" color="#fff" :src="androidIcon"></a-avatar>
                <p>Android下载</p>
              </div>
            </a-button>
          </div>
          <div  style="width:100%">
            <img  style="width:40%" :src="miniProgram" alt="" srcset="">
            <a-button  type="primary" style="margin-top: 15px;background:#6c6dba;border-color:#6c6dba" size="mini">
              <div style="display:flex;align-items:center;">
                <a-avatar #icon  :size="20" color="#fff" :src="minipro"></a-avatar>
                <p>微信小程序</p>
              </div>
            </a-button>
            <p style="color:#fff">无需下载扫码即用</p>
          </div>
        </div> -->
    </div>
    <Publicfooter v-if="isMobile==false"></Publicfooter>
  </div>
</template>
<script>
import Header from '../components/header'
import Publicfooter  from '../components/footer'
export default {
  data(){
    return{
      nowActive: ["downloadCenter"],
      AndroidQR :'', // 安卓二维码
      AndroidUrl :'', // 安卓链接
      IOSQR: '', // IOS二维码
      IOSUrl: '', // ios链接
      focusUsQR: '', 
      miniProgram: '', // 小程序二维码
      isMobile: false,
      appleIcon: require('../assets/img/apple-fill.png'),
      androidIcon: require ('../assets/img/android.png'),
      minipro: require('../assets/img/minipro.png'),
      screenHeight: document.body.clientHeight + 'px',    // 屏幕高
    }
  },
  components:{
    Header,
    Publicfooter
  },
  mounted(){
    this.getQRcode()
    if (this._isMobile()) {
      this.isMobile = true
      // this.$router.replace('/m_index');
    } else {
      this.isMobile = false
      // this.$router.replace('/pc_index');
    }
  },
  methods:{
　  _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    getQRcode(){
      this.$axios.post(
        this.$api.server_api.v1.QRCODE_LIST
      ).then( res=> {
        for( let i in res.data.data){
          if( res.data.data[i].platform == 1){
            this.AndroidQR = res.data.data[i].thumb
            this.AndroidUrl = res.data.data[i].url
          }else if(res.data.data[i].platform == 2){
            this.IOSQR =  res.data.data[i].thumb
            this.IOSUrl =  res.data.data[i].url
          }else if(res.data.data[i].platform == 3){
            this.miniProgram = res.data.data[i].thumb
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.downloadAppCont{
  width: 100%;
  height: auto;
  background: url('../assets/img/background.png') no-repeat center;
  background-size: 100% 100%;
  /* padding-top: 5%; */
  background-size:cover;
}
.downloadAppMobileCont{
  width: 100%;
  height: auto;
  background: url('../assets/img/445566.png') no-repeat center;
  background-size: 100% 100%;
  /* padding-top: 5%; */
  background-size:cover;
  padding-top: 40px
}
.topTitle{
	
}
.downloadQrCont{
  color: #fff;
  display: flex;
  margin-top: 50px;
  /* width: 50%; */
  align-items: flex-start;
  margin-left:1
}
.downloadmpQrCont{
  color: #fff;
  /* width: 50%; */
  text-align: center;
  margin-top: 50px;
}
.largeFrame{
  width:42%;
  margin-left:46%;
  text-align:center;
  position:absolute;
  top:40%;
}
.smallFrame {
  display:flex;
}
.smallFrame-text {
  margin-top: 10%;
  font-size: 24px;
  color: #fff;
}
.smallFrame-img {
  width:100%;
}
.smallFrame-imgs {
  width:100%;
  margin-left: 2%;
}
.exit_hover .exit1 {
  display:inline-block;
}
.exit_hover .exit2 {
  display:none;
}
.exit_hover:hover .exit1 {
  display:none;
}
.exit_hover:hover .exit2 {
  width: 168px;
  height: 168px;
  display:inline-block;
}
.appLargeFrame {
  text-align: center;
  position: relative;
  top: 70%;
}
.appImg {
  width:100%;
}
.appImg-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 9px;
}
.appImg-text {
  margin-top: 10%;
  font-size: 18px;
  color: #fff;
}
</style>