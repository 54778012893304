<template>
  <div>
    <Header :nowActive="nowActive" v-if="isMobile==false"></Header>
    <PublicBanner v-if="isMobile==false"></PublicBanner>
    <div style="width: 100%">
      <img style="width: 100%" src="../assets/img/about.jpg" />
    </div>
	<div id="block6" v-if="!isMobile"><developmentHistory></developmentHistory></div>
	<div id="block7" v-if="!isMobile"><brandValue></brandValue></div>
    <Publicfooter ref="footer" v-if="isMobile==false"></Publicfooter>
  </div>
</template>

<script>
import Header from "../components/header.vue";
import PublicBanner from "../components/banner.vue";
import Publicfooter from "../components/footer.vue";
import developmentHistory from "./developmentHistory";
import brandValue from "./brandValue";

export default {
  components: {
    Header,
    PublicBanner,
    Publicfooter,
	developmentHistory,
	brandValue
  },
  data() {
    return {
      nowActive: ["aboutKxx"],
	  isMobile:false
    };
  },
  mounted(){
  	  this.isMobile = this.$refs.footer.isMobile;
  }
};
</script>

<style>
</style>