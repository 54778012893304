<template>
  <div ref="boxRef" style="width: 100%; height: auto" id="scrollbody">
    <!-- 头部导航 -->
    <Header
      :nowActive="nowActive"
      :activeindex="activeindex"
      @scrollTopto="scrollTopto"
    ></Header>
    <!-- 轮播图 -->
    <Indexbanner v-if="!isMobile"></Indexbanner>
    <!-- 内容 -->
    <div v-if="!isMobile">
      <businessScenario></businessScenario>
    </div>
    <div id="longpage" v-if="isMobile">
      <div :class="{ 'pt-page-moveFromBottom': as0 }" style="z-index: -99">
        <!-- <img id="longpagepic" v-for="item in bannerList"
          style="display: inline-block;height: auto;width: 100%;radius:300px;" :src="item.thumb" alt="" srcset=""> -->
        <Indexbanner></Indexbanner>
      </div>
      <div id="block1" :class="{ 'pt-page-moveFromBottom': as1 }">
        <businessScenario></businessScenario>
      </div>
      <!-- <div ref="block3"> <companyProfile ></companyProfile></div> -->
      <div id="block2" :class="{ 'pt-page-moveFromBottom': as2 }">
        <functionIntroduction></functionIntroduction>
      </div>
      <div id="block3" :class="{ 'pt-page-moveFromBottom': as3 }">
        <productService></productService>
      </div>
      <div id="block4" :class="{ 'pt-page-moveFromBottom': as4 }">
        <downloadCenter></downloadCenter>
      </div>
      <div id="block5" :class="{ 'pt-page-moveFromBottom': as5 }">
        <aboutKxx></aboutKxx>
      </div>
      <div id="block6" :class="{ 'pt-page-moveFromBottom': as6 }">
        <developmentHistory></developmentHistory>
      </div>
      <div id="block7" :class="{ 'pt-page-moveFromBottom': as7 }">
        <brandValue></brandValue>
      </div>
      <div id="block8" :class="{ 'pt-page-moveFromBottom': as8 }">
        <openPlatform></openPlatform>
      </div>
      <div id="block9" :class="{ 'pt-page-moveFromBottom': as9 }">
        <userManual></userManual>
      </div>
    </div>

    <!-- 页脚 -->
    <Publicfooter ref="footer"></Publicfooter>
    <!-- <div style="width:90%;margin-left:5%;">
      <img style="width:100%" src="../assets/img/index Framework.jpg" alt="" srcset="">
    </div> -->
    <!-- <div style="display:flex;width:90%;margin-left:5%;margin-top:50px">
      <div style="width:60%">
        <p style="font-size:35px;font-weight:500;margin-bottom:5px">聚合平台介绍</p>
        <p style="font-size:18px;line-height:30px">聚合平台是由北京巨游科技有限公司独立开发的全网智能运力调度平台，是国内创新的智能聚合配送服务平台。采用大数据+AI智能，为全国美饿等自配送商户、线上平台、个人，提供高效、便捷、省钱、贴心的同城配送服务。她聚焦共享经济，以聚合服务为依托，专注于专人直送，同城跑腿，专车快送，顺路配送等，为用户提供7×24小时，平均1分钟响应、10分钟上门、同城1小时送达的聚合速递服务。开始送，一切从您开始！</p>
      </div>
      <div style="width:40%;text-align:center">
        <img src="../assets/img/introduce.jpg" width="90%" alt="" srcset="">
      </div>
    </div> -->
    <!-- <div style="width:95%;margin-left:2.5%">
      <img style="width:100%" src="../assets/img/index Framework 3.jpg" alt="" srcset="">
    </div>
    <div>
      <img style="width:100%" src="../assets/img/index Framework 4.jpg" alt="" srcset="">
    </div> -->
    <!-- <div>
       <p style="color: #2f70dc;font-size:36px;text-align:center;margin-top:70px">下载APP操作更便捷</p> -->
    <!-- <div class="downloadCont">
        <div style="display:flex;align-items:center;height:100%;margin-left:10%;width:40%">
          <img style="width:45%;" src="../assets/img/phonePic.png" alt="" srcset="">
        </div> -->
    <!-- <div style="padding-top: 100px"> -->
    <!-- <p style="color:#fff;font-size:29px;margin-left:15px;font-weight:500">用户端</p>
            <div class="appBtn" style="margin-top:20px"> -->
    <!-- <a-popover >
                <template slot="content">
                  <img src="../assets/img/appqr.png" alt="" srcset="">
                </template>
                <a-button type='ghost' size="large">
                  <a-icon type="apple" />
                  Appstore
                </a-button>
              </a-popover> -->
    <!-- <a-popover >
                <template slot="content">
                  <img :src="AndroidQR" alt="" srcset="">
                </template>
                <a-button  type='ghost' size="large">
                  <a-icon type="android" />
                  Android
                </a-button>
              </a-popover>       
              <a-popover >
                <template slot="content">
                  <img :src="miniProgram" alt="" srcset="">
                </template> 
                <a-button type='ghost' size="large" >
                  <a-icon type="wechat" />
                  微信小程序
                </a-button>
              </a-popover> 
            </div>   -->
    <!-- <p style="color:#fff;font-size:29px;margin-left:15px;margin-top:50px;font-weight:500">配送端</p>
              <div class="appBtn" style="margin-top:20px"> -->
    <!-- <a-popover >
                  <template slot="content">
                    <img src="../assets/img/appqr.png" alt="" srcset="">
                  </template>
                  <a-button  type='ghost'size="large">
                    <a-icon type="apple" />
                    Appstore
                  </a-button>
                </a-popover> -->
    <!-- <a-popover >
                  <template slot="content">
                    <img :src="AndroidQR" alt="" srcset="">
                  </template>
                  <a-button type='ghost'  size="large">
                    <a-icon type="android" />
                    Android
                  </a-button>
                </a-popover>  
              </div>     
          </div> -->
    <!-- </div> 
    </div> -->
  </div>
</template>
<script>
import { Icon } from "ant-design-vue";
import Header from "../components/header";
import Indexbanner from "../components/banner";
import Publicfooter from "../components/footer";
import functionIntroduction from "./functionIntroduction";
import companyProfile from "./companyProfile";
import productService from "./productService";
import downloadCenter from "./downloadCenter";
import aboutKxx from "./aboutKxx";
import developmentHistory from "./developmentHistory";
import brandValue from "./brandValue";
import openPlatform from "./openPlatform";
import businessScenario from "./businessScenario";
import userManual from "./userManual";

import qs from "qs";
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: require("../assets/js/iconfont.js"),
});
export default {
  data() {
    return {
      nowActive: ["index"],
      activeindex: 0,
      bannerList: [],
      IOSQR: "",
      AndroidQR: "",
      miniProgram: "",
      isMobile: false,
      currentFloor: 1, //当前页面下表
      boxScreenHeight: null, //当前页面高度
      sArea: [], //区域高度的集合
      Areaname: [
        "业务场景",
        "功能介绍",
        "产品服务",
        "下载中心",
        "快小象简介",
        "发展历程",
        "价值愿景",
        "开放平台",
      ], //区域名称集合
      scrollflag: true,
      as0: true,
      as1: false,
      as2: false,
      as3: false,
      as4: false,
      as5: false,
      as6: false,
      as7: false,
      as8: false,
      as9: false,
    };
  },
  components: {
    IconFont,
    Header,
    Indexbanner,
    Publicfooter,
    functionIntroduction,
    companyProfile,
    downloadCenter,
    aboutKxx,
    developmentHistory,
    brandValue,
    openPlatform,
    businessScenario,
    productService,
    userManual,
  },
  mounted() {
    this.getQRcode();
    this.getBanner();
    this.isMobile = this.$refs.footer.isMobile;
    // 监听事件添加和销毁
    this.$nextTick(() => {
      // document.addEventListener('scroll', this.handleScroll)
      // console.log(this.bannerList)
      document.scrollingElement.scrollTop = 0;
      let propval = {
        name: "主页",
        tabNum: "longpage",
        index: 0,
      };
      this.scrollTopto(propval);
      // document.getElementById("block1").offsetTop - 65
      // this.activeindex = 0;
    });
  },
  beforeDestroy() {
    // document.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    getBanner() {
      this.$axios.post(this.$api.server_api.v1.GET_BANNER).then((res) => {
        console.log(res.data.data);
        this.bannerList = res.data.data;
      });
      // this.as0 = true
    },
    // 滚动事件

    handleScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop; //获取滚动距离
      this.scrollTop = scrollTop; //data里return了一个全局的scrollTop
      console.log(scrollTop, "触发吗");
      //这边距离我是通过打印scrollTop大致自己粗略的计算了下，用来判断滚轮滚动相应的距离改变导航栏对应菜单样式
      if (this.scrollflag) {
        this.$nextTick(() => {
          if (scrollTop < 487) {
            this.activeindex = 0;
          }
          if (scrollTop > document.getElementById("block1").offsetTop - 65) {
            this.activeindex = 1;
          }
          if (scrollTop > document.getElementById("block2").offsetTop - 65) {
            this.activeindex = 2;
          }
          if (scrollTop > document.getElementById("block3").offsetTop - 65) {
            this.activeindex = 3;
          }
          if (scrollTop > document.getElementById("block4").offsetTop - 65) {
            this.activeindex = 4;
          }
          if (scrollTop > document.getElementById("block5").offsetTop - 65) {
            this.activeindex = 5;
          }
          if (scrollTop > document.getElementById("block6").offsetTop - 65) {
            this.activeindex = 6;
          }
          if (scrollTop > document.getElementById("block7").offsetTop - 65) {
            this.activeindex = 7;
          }
          if (scrollTop > document.getElementById("block8").offsetTop - 65) {
            this.activeindex = 8;
          }
          if (scrollTop > document.getElementById("block9").offsetTop - 65) {
            this.activeindex = 9;
          }
          // console.log(this.activeindex)
        });
      }
    },
    //跳转操作
    scrollTopto(val) {
      console.log(val, "跳转操作");
      this.scrollflag = false;
      let propval = {
        name: val.name,
        tabNum: val.tabNum,
        index: val.index,
      };
      this.activeindex = propval.index;
      let scrollheight = document.getElementById(propval.tabNum).offsetTop;
      this.$nextTick(() => {
        if (propval.tabNum == "longpage") {
          document.scrollingElement.scrollTop = 0;
        } else {
          document.scrollingElement.scrollTop = scrollheight - 60;
        }
        this.scrollflag = true;
      });
      switch (val.index) {
        case 0:
          this.as0 = true;
          this.as1 = false;
          this.as2 = false;
          this.as3 = false;
          this.as4 = false;
          this.as5 = false;
          this.as6 = false;
          this.as7 = false;
          this.as8 = false;
          this.as9 = false;
          break;
        case 1:
          this.as0 = false;
          this.as1 = true;
          this.as2 = false;
          this.as3 = false;
          this.as4 = false;
          this.as5 = false;
          this.as6 = false;
          this.as7 = false;
          this.as8 = false;
          this.as9 = false;

          break;
        case 2:
          this.as0 = false;
          this.as1 = false;
          this.as2 = true;
          this.as3 = false;
          this.as4 = false;
          this.as5 = false;
          this.as6 = false;
          this.as7 = false;
          this.as8 = false;
          this.as9 = false;

          break;
        case 3:
          this.as0 = false;
          this.as1 = false;
          this.as2 = false;
          this.as3 = true;
          this.as4 = false;
          this.as5 = false;
          this.as6 = false;
          this.as7 = false;
          this.as8 = false;
          this.as9 = false;

          break;
        case 4:
          this.as0 = false;
          this.as1 = false;
          this.as2 = false;
          this.as3 = false;
          this.as4 = true;
          this.as5 = false;
          this.as6 = false;
          this.as7 = false;
          this.as8 = false;
          this.as9 = false;

          break;
        case 5:
          this.as0 = false;
          this.as1 = false;
          this.as2 = false;
          this.as3 = false;
          this.as4 = false;
          this.as5 = true;
          this.as6 = false;
          this.as7 = false;
          this.as8 = false;
          this.as9 = false;

          break;
        case 6:
          this.as0 = false;
          this.as1 = false;
          this.as2 = false;
          this.as3 = false;
          this.as4 = false;
          this.as5 = false;
          this.as6 = true;
          this.as7 = false;
          this.as8 = false;
          this.as9 = false;

          break;
        case 7:
          this.as0 = false;
          this.as1 = false;
          this.as2 = false;
          this.as3 = false;
          this.as4 = false;
          this.as5 = false;
          this.as6 = false;
          this.as7 = true;
          this.as8 = false;
          this.as9 = false;

          break;
        case 8:
          this.as0 = false;
          this.as1 = false;
          this.as2 = false;
          this.as3 = false;
          this.as4 = false;
          this.as5 = false;
          this.as6 = false;
          this.as7 = false;
          this.as8 = true;
          this.as9 = false;
          break;
        case 9:
          this.as0 = false;
          this.as1 = false;
          this.as2 = false;
          this.as3 = false;
          this.as4 = false;
          this.as5 = false;
          this.as6 = false;
          this.as7 = false;
          this.as8 = false;
          this.as9 = true;
          break;
      }
    },

    logoClick() {
      this.$router.push("/").catch((err) => {});
    },
    getQRcode() {
      this.$axios.post(this.$api.server_api.v1.QRCODE_LIST).then((res) => {
        // this.QRcdoeList = res.data.data
        for (let i in res.data.data) {
          if (res.data.data[i].platform == 1) {
            this.AndroidQR = res.data.data[i].thumb;
          } else if (res.data.data[i].platform == 2) {
            this.IOSQR = res.data.data[i].thumb;
          } else if (res.data.data[i].platform == 3) {
            this.miniProgram = res.data.data[i].thumb;
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.cardAnimate:hover {
  display: inline-block;
  animation: wobble;
  /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s;
  /* don't forget to set a duration! */
}

.serverTit {
  font-size: 48px;
  color: #fff;
  text-align: center;
  line-height: 300px;
  font-weight: 500;
}

.serverInfo {
  color: #fff;
  margin-top: 2%;
  margin-left: 5%;
}

.serveAnimate:hover {
  animation: jello;
  /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s;
  /* don't forget to set a duration! */
}

.server-bg {
  width: 100%;
  height: 100%;
}

.serveAnimate:hover > .server-bg {
  background-color: rgba(24, 144, 255, 0.5);
}

.wm:hover,
.flower:hover,
.drink:hover,
.cake:hover,
.dayily:hover,
.personal:hover {
  animation: swing;
  /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s;
  /* don't forget to set a duration! */
}

.appBtn:not(:first-child) > button {
  margin-left: 15px;
}

.psCont {
  /* background-size: 130% 100%; */
  color: #fff;
  margin-top: 80px;
  /* padding-left:5%; */
  background: #5591f6;
}

.downloadCont {
  width: 100%;
  height: 450px;
  margin-top: 50px;
  background: linear-gradient(to bottom right, #5d98fb, #3071dd);
  background-size: 100% 100%;
  display: flex;
}

.pt-page-moveFromBottom {
  -webkit-animation: moveFromBottom 0.6s ease both;
  -moz-animation: moveFromBottom 0.6s ease both;
  animation: moveFromBottom 0.6s ease both;
}

.pt-page-moveToTop {
  -webkit-animation: moveToTop 0.6s ease both;
  -moz-animation: moveToTop 0.6s ease both;
  animation: moveToTop 0.6s ease both;
}

@-webkit-keyframes moveFromBottom {
  from {
    -webkit-transform: translateY(100%);
  }
}

@-moz-keyframes moveFromBottom {
  from {
    -moz-transform: translateY(100%);
  }
}

@keyframes moveFromBottom {
  from {
    transform: translateY(100%);
  }
}

@-webkit-keyframes moveToTop {
  to {
    -webkit-transform: translateY(-100%);
  }
}

@-moz-keyframes moveToTop {
  to {
    -moz-transform: translateY(-100%);
  }
}

@keyframes moveToTop {
  to {
    transform: translateY(-100%);
  }
}
</style>
