<template>
  <div>
    <!-- <Header :nowActive='nowActive'></Header> -->
    <div class="enter" v-if="isMobile == false" :style="{height:screenHeight}">
      <p style="visibility:hidden">123</p>
      <p style=" margin-left: 50%;color:#fff;;font-size:35px;font-weight:bold;margin-top:10%">商户服务</p>
      <div class="enterBtn">
        <a-button size="large" shape="round" @click="toAdminPage" ghost style="width: 10%;height:50px;width:200px;color:#fff;border-color:#fff">普通商户</a-button>
        <a-button size="large" shape="round"  @click="toAdminPage2" ghost style="width: 10%;height:50px;width:200px;color:#fff;border-color:#fff;margin-left:50px">品牌商户</a-button>
      </div>
    </div>
    <div  v-else :style="{height:screenHeight}" class="entermobile">
      <p style="visibility:hidden">123</p>
      <p style=" color:#fff;;font-size:35px;font-weight:bold;margin-top:50%;text-align:center">商户服务</p>
      <div style="text-align:center">
        <a-button size="large" shape="round" @click="toAdminPage" ghost style="width: 55%;height:50px;;color:#fff;border-color:#fff;margin-top:30px">普通商户</a-button>
        <a-button size="large" shape="round"  @click="toAdminPage2" ghost style="width: 55%;height:50px;;color:#fff;border-color:#fff;margin-top:50px">品牌商户</a-button>
      </div>
    </div>
    <!-- <Publicfooter></Publicfooter> -->
  </div>
</template>
<script>
import Header from '../components/header'
import Publicfooter from '../components/footer'
export default {
  data(){
    return{
      nowActive: [2],
        screenWidth: document.body.clientWidth,     // 屏幕宽
        screenHeight: document.body.clientHeight + 'px',    // 屏幕高
        isMobile: false
    }
  },
  components:{
    Header,
    Publicfooter
  },
  mounted(){
    if (this._isMobile()) {
      this.isMobile = true
      // this.$router.replace('/m_index');
    } else {
      this.isMobile = false
      // this.$router.replace('/pc_index');
    }
  },
  methods:{
　  _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    toAdminPage(){
      window.open('https://kxx.kuaixiaoxiang.com/')
    },
    toAdminPage2(){
      window.open('https://kxx.kuaixiaoxiang.com/')
    }
  }
}
</script>
<style scoped>
.enter{
  background: url('../assets/img/businness.png') no-repeat center;
  background-size: 100% 100%;
  width: 100%;
  /* height: 720px; */
}
.entermobile{
  background: url('../assets/img/businessmobile.png') no-repeat center;
  background-size: 100% 100%;
  width: 100%;
}
.enterBtn{
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  height: 85%;
  padding-top: 10%;
  margin-left: 50%;
  width:40%
}
</style>