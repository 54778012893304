<template>
  <!-- 产品服务 -->
  <div>
    <!-- <Header :nowActive="nowActive" v-if="isMobile==false"></Header> -->
    <!-- <PublicBanner v-if="isMobile==false"></PublicBanner> -->
	<!-- <div  v-if="isMobile==false"><functionIntroduction ></functionIntroduction></div> -->
    <div style="width: 100%">
      <img style="width: 100%" src="../assets/img/productService.jpg" />
    </div>
    <!-- <Publicfooter ref="footer"  v-if="isMobile==false"></Publicfooter> -->
  </div>
</template>

<script>
import Header from "../components/header.vue";
import PublicBanner from "../components/banner.vue";
import Publicfooter from "../components/footer.vue";
import functionIntroduction from "./functionIntroduction.vue";

export default {
  components: {
    Header,
    PublicBanner,
    Publicfooter,
	functionIntroduction
  },
  data() {
    return {
      nowActive: ["productService"],
	  isMobile:false
    };
  },
  mounted(){
    // this.isMobile = this.$refs.footer.isMobile;
  },
};
</script>

<style>
</style>
