<template>
  <div>
    <Header :nowActive="nowActive" v-if="isMobile == false"></Header>

    <!-- PC端 -->
    <div class="downloadAppCont" v-if="isMobile == false">
      <div style="width: 50%; margin-bottom: 50px">
        <div
          style="
            border-bottom: 2px solid #d3d3d3;
            padding-bottom: 10px;
            font-size: 16px;
            color: black;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
          "
        >
          <div>
            <span style="cursor: pointer" @click="goBack">付费文章</span> /
            <span style="color: #909399">文章详情</span>
          </div>
          <div>{{ time }} · 专栏文章[收费]</div>
        </div>
        <div style="margin-top: 20px">
          <h1>{{ title }}</h1>
          <div style="display: flex; justify-content: center">
            <img src="@/assets/img/IMG_6478.jpg" alt="" width="400" />
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <!-- <div v-else class="downloadAppMobileCont" :style="{ height: screenHeight }">
        
      </div> -->
    <Publicfooter v-if="isMobile == false"></Publicfooter>
  </div>
</template>
<script>
import Header from "../components/header";
import Publicfooter from "../components/footer";
export default {
  data() {
    return {
      nowActive: ["paidArticles"],
      isMobile: false,
      screenHeight: document.body.clientHeight + "px", // 屏幕高
      time: "",
      title: "",
    };
  },
  components: {
    Header,
    Publicfooter,
  },
  mounted() {
    console.log(this.$route.query.name, "查看", this.$route.query.time);
    this.title = this.$route.query.name;
    let dateTimeParts = this.$route.query.time.split(/[年月日:]/);
    let year = dateTimeParts[0];
    let month = dateTimeParts[1].padStart(2, "0");
    let day = dateTimeParts[2].padStart(2, "0");
    this.time = `${year}-${month}-${day}`;

    if (this._isMobile()) {
      this.isMobile = true;
      // this.$router.replace('/m_index');
    } else {
      this.isMobile = false;
      // this.$router.replace('/pc_index');
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.downloadAppCont {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #9a9a9a;
  /* background: url("../assets/img/background.png") no-repeat center; */
  /* background-size: 100% 100%; */
  /* padding-top: 5%; */
  /* background-size: cover; */
}
.downloadAppMobileCont {
  width: 100%;
  height: auto;
  /* background: url("../assets/img/445566.png") no-repeat center; */
  /* background-size: 100% 100%; */
  /* padding-top: 5%; */
  /* background-size: cover; */
  padding-top: 40px;
}
.topTitle {
}
.downloadQrCont {
  color: #fff;
  display: flex;
  margin-top: 50px;
  /* width: 50%; */
  align-items: flex-start;
  margin-left: 1;
}
.downloadmpQrCont {
  color: #fff;
  /* width: 50%; */
  text-align: center;
  margin-top: 50px;
}
.largeFrame {
  width: 42%;
  margin-left: 46%;
  text-align: center;
  position: absolute;
  top: 40%;
}
.smallFrame {
  display: flex;
}
.smallFrame-text {
  margin-top: 10%;
  font-size: 24px;
  color: #fff;
}
.smallFrame-img {
  width: 100%;
}
.smallFrame-imgs {
  width: 100%;
  margin-left: 2%;
}
.exit_hover .exit1 {
  display: inline-block;
}
.exit_hover .exit2 {
  display: none;
}
.exit_hover:hover .exit1 {
  display: none;
}
.exit_hover:hover .exit2 {
  width: 168px;
  height: 168px;
  display: inline-block;
}
.appLargeFrame {
  text-align: center;
  position: relative;
  top: 70%;
}
.appImg {
  width: 100%;
}
.appImg-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 9px;
}
.appImg-text {
  margin-top: 10%;
  font-size: 18px;
  color: #fff;
}
</style>
