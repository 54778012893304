import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue"; //首页
import cityPartner from "../views/cityPartner.vue"; //城市合伙人
import personalEntrance from "../views/personalEntrance.vue"; //普通用户入口
import register from "../views/register.vue"; //注册
import forget from "../views/forgetPassword.vue"; //忘记密码
import downloadCenter from "../views/downloadCenter.vue"; //下载中心
import businessServer from "../views/businessServer.vue"; //商户入口
import wxpay from "../views/wxpay.vue"; //微信支付
import wxpay2 from "../views/wxpay2.vue"; //微信支付
import rechargeResult from "../views/rechargeResult.vue"; //微信支付结果
import alipay from "../views/alipay.vue"; //支付宝支付
import alipayStatus from "../views/alipayStatus.vue"; //支付宝支付结果

import businessScenario from "../views/businessScenario.vue"; //首页-业务场景
import companyProfile from "../views/companyProfile.vue"; //首页-公司简介
import functionIntroduction from "../views/functionIntroduction.vue"; //产品服务-功能介绍
import productService from "../views/productService.vue"; //产品服务-产品服务
import aboutKxx from "../views/aboutKxx.vue"; //关于我们-快小象简介
import developmentHistory from "../views/developmentHistory.vue"; //关于我们-发展历程
import brandValue from "../views/brandValue.vue"; //关于我们-愿景+品牌价值
import kxxNews from "../views/kxxNews.vue"; //快小象新闻
import openPlatform from "../views/openPlatform.vue"; //开放平台
import serviceProviderJoining from "../views/serviceProviderJoining.vue"; //服务商加盟
import paidArticles from "../views/paidArticles.vue"; //付费文章
import articleDetails from "../views/articleDetails.vue"; //付费文章-详情
import noPage from "../views/404.vue"; //404
import userManual from "../views/userManual.vue"; //使用教程

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    meta: {
      title: "首页",
    },
  },
  {
    path: "/businessScenario",
    name: "businessScenario",
    component: businessScenario,
    meta: {
      title: "业务场景",
    },
  },
  {
    path: "/companyProfile",
    name: "companyProfile",
    component: companyProfile,
    meta: {
      title: "公司简介",
    },
  },
  {
    path: "/productService",
    name: "productService",
    component: productService,
    meta: {
      title: "产品服务",
    },
  },
  {
    path: "/kxxNews",
    name: "kxxNews",
    component: kxxNews,
    meta: {
      title: "快小象新闻",
    },
  },
  {
    path: "/functionIntroduction",
    name: "functionIntroduction",
    component: functionIntroduction,
    meta: {
      title: "功能介绍",
    },
  },
  {
    path: "/aboutKxx",
    name: "aboutKxx",
    component: aboutKxx,
    meta: {
      title: "快小象简介",
    },
  },
  {
    path: "/developmentHistory",
    name: "developmentHistory",
    component: developmentHistory,
    meta: {
      title: "发展历程",
    },
  },
  {
    path: "/brandValue",
    name: "brandValue",
    component: brandValue,
    meta: {
      title: "价值愿景",
    },
  },
  {
    path: "/cityPartner",
    name: "cityPartner",
    component: cityPartner,
    meta: {
      title: "招商合作",
    },
  },
  {
    path: "/personalEntrance",
    name: "personalEntrance",
    component: personalEntrance,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/register",
    name: "register",
    component: register,
    meta: {
      title: "注册",
    },
  },
  {
    path: "/forget",
    name: "forget",
    component: forget,
    meta: {
      title: "忘记密码",
    },
  },
  {
    path: "/downloadCenter",
    name: "downloadCenter",
    component: downloadCenter,
    meta: {
      title: "下载中心",
    },
  },
  {
    path: "/businessServer",
    name: "businessServer",
    component: businessServer,
    meta: {
      title: "商户入口",
    },
  },
  {
    path: "/openPlatform",
    name: "openPlatform",
    component: openPlatform,
    meta: {
      title: "开放平台",
    },
  },
  {
    path: "/serviceProviderJoining",
    name: "serviceProviderJoining",
    component: serviceProviderJoining,
    meta: {
      title: "服务商加盟",
    },
  },
  {
    path: "/wxpay",
    name: "wxpay",
    component: wxpay,
    meta: {
      title: "支付",
    },
  },
  {
    path: "/wxpay2",
    name: "wxpay2",
    component: wxpay2,
    meta: {
      title: "微信支付",
    },
  },
  {
    path: "/rechargeResult",
    name: "rechargeResult",
    component: rechargeResult,
    meta: {
      title: "支付结果",
    },
  },
  {
    path: "/alipay",
    name: "alipay",
    component: alipay,
    meta: {
      title: "支付宝支付",
    },
  },
  {
    path: "/alipayStatus",
    name: "alipayStatus",
    component: alipayStatus,
    meta: {
      title: "支付宝支付",
    },
  },
  {
    path: "/404",
    name: "404",
    component: noPage,
    meta: {
      title: "404",
    },
  },
  {
    path: "/paidArticles",
    name: "paidArticles",
    component: paidArticles,
    meta: {
      title: "付费文章",
    },
  },
  {
    path: "/paidArticles/articleDetails",
    name: "articleDetails",
    component: articleDetails,
    meta: {
      title: "文章详情",
    },
  },
  {
    path: "/userManual",
    name: "userManual",
    component: userManual,
    meta: {
      title: "使用教程",
    },
  },
  // 所有未定义路由，全部重定向到404页
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  routes: routes,
});

// 修改重复点击 首页 报错
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

function setUrl() {
  let UrlParams = {};
  console.log(
    location.href.includes("dyPrivacy.php"),
    "location.href.indexOf()"
  );
  if (location.href.indexOf("dyPrivacy.php")) {
    UrlParams = getUrlParams(location.href);
    console.log(UrlParams, "UrlParams");
    if (UrlParams.type && UrlParams.type == "1") {
      location.href = "/androidPrivacy.html";
    } else if (UrlParams.type && UrlParams.type == "2") {
      location.href = "/androidPrivacy2.html";
    }
  }
}

function getUrlParams(url) {
  // 通过 ? 分割获取后面的参数字符串
  let urlStr = url.split("?")[1];
  console.log(urlStr, "getUrlParams");
  // 创建空对象存储参数
  let obj = {};
  if (!urlStr) return "";
  // 再通过 & 将每一个参数单独分割出来
  let paramsArr = urlStr.split("&");
  for (let i = 0, len = paramsArr.length; i < len; i++) {
    // 再通过 = 将每一个参数分割为 key:value 的形式
    let arr = paramsArr[i].split("=");
    obj[arr[0]] = arr[1];
  }
  return obj;
}

router.beforeEach((to, from, next) => {
  setUrl();
  next();
});

export default router;
