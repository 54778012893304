<template>
  <div>
    <Header :nowActive="nowActive" v-if="isMobile == false"></Header>

    <!-- PC端 -->
    <div class="downloadAppCont" v-if="isMobile == false">
      <div style="width: 50%; margin-bottom: 50px">
        <div
          style="
            border-bottom: 2px solid #d3d3d3;
            padding-bottom: 10px;
            font-size: 18px;
            color: black;
          "
        >
          预览
        </div>
        <div
          style="
            border-bottom: 2px solid #d3d3d3;
            margin: 10px 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            color: black;
            padding: 10px 50px;
            cursor: pointer;
          "
          v-for="(item, index) in articleData"
          :key="index"
          @click="clickDetails(item)"
        >
          <div>
            <div style="margin-bottom: 20px; font-size: 18px">
              {{ item.name }}
            </div>
            <div style="color: #909399">时间 {{ item.time }}</div>
          </div>
          <div style="display: flex; align-items: center">
            <img src="@/assets/img/eye.svg" alt="" width="20" />
            <div style="margin-left: 30px">{{ item.view }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <!-- <div v-else class="downloadAppMobileCont" :style="{ height: screenHeight }">
      <div class="appLargeFrame">
        <div style="display: flex">
          <div class="appImg">
            <a :href="IOSUrl">
              <img src="../assets/img/app.png" class="appImg-img" />
            </a>
            <div class="appImg-text">
              <p>IOS</p>
            </div>
          </div>
          <div class="appImg">
            <a :href="AndroidUrl">
              <img src="../assets/img/and.png" class="appImg-img" />
            </a>
            <div class="appImg-text">
              <p>Android</p>
            </div>
          </div>
          <div class="appImg">
            <img src="../assets/img/win.png" class="appImg-img" />
            <div class="appImg-text">
              <p>Windows</p>
            </div>
          </div>
          <div class="appImg">
            <img :src="miniProgram" class="appImg-img" />
            <div class="appImg-text">
              <p>小程序</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <Publicfooter v-if="isMobile == false"></Publicfooter>
  </div>
</template>
<script>
import Header from "../components/header";
import Publicfooter from "../components/footer";
export default {
  data() {
    return {
      nowActive: ["paidArticles"],
      AndroidQR: "", // 安卓二维码
      AndroidUrl: "", // 安卓链接
      IOSQR: "", // IOS二维码
      IOSUrl: "", // ios链接
      focusUsQR: "",
      miniProgram: "", // 小程序二维码
      isMobile: false,
      appleIcon: require("../assets/img/apple-fill.png"),
      androidIcon: require("../assets/img/android.png"),
      minipro: require("../assets/img/minipro.png"),
      screenHeight: document.body.clientHeight + "px", // 屏幕高
      articleData: [
        {
          name: "详解积分商城：是回馈商家还是智商税？",
          time: "2024年4月17日11:52:28",
          view: 910,
        },
        {
          name: "快小象聚合平台，提高外卖商家生产力的新工具",
          time: "2024年3月13日10:00:28",
          view: 839,
        },
        {
          name: "节日爆单怎么办？无从下手的你，一定不要错过",
          time: "2024年2月20日15:30:42",
          view: 435,
        },
        {
          name: "鲜花行业五月份第二次爆单即将来临",
          time: "2024年1月10日09:05:52",
          view: 145,
        },
        {
          name: "快小象2.0版本即将发布，看看有哪些新特性呢",
          time: "2023年12月02日12:02:01",
          view: 560,
        },
        {
          name: "都说聚合配送好，它到底能解决什么问题？",
          time: "2023年11月15日12:00:56",
          view: 300,
        },
        {
          name: "催单、差评、顾客不满意！怎么解决？",
          time: "2023年10月08日08:30:00",
          view: 460,
        },
        {
          name: "适合春天的9种粉色系鲜花",
          time: "2023年9月22日11:32:01",
          view: 320,
        },
        {
          name: "有哪些春天开的花？花语是什么？",
          time: "2023年8月02日12:22:40",
          view: 390,
        },
        {
          name: "春天就养这6种花，有幸福美满、财运亨通的寓意，而且浇水就能活",
          time: "2023年8月11日08:01:59",
          view: 163,
        },
      ],
    };
  },
  components: {
    Header,
    Publicfooter,
  },
  mounted() {
    if (this._isMobile()) {
      this.isMobile = true;
      // this.$router.replace('/m_index');
    } else {
      this.isMobile = false;
      // this.$router.replace('/pc_index');
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    clickDetails(item) {
      console.log(item, "详情");
      //   this.$router.push("/paidArticles/articleDetails");
      this.$router.push({
        name: "articleDetails",
        query: { name: item.name, time: item.time },
      });
    },
  },
};
</script>

<style scoped>
.downloadAppCont {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #9a9a9a;
  /* background: url("../assets/img/background.png") no-repeat center; */
  /* background-size: 100% 100%; */
  /* padding-top: 5%; */
  /* background-size: cover; */
}
.downloadAppMobileCont {
  width: 100%;
  height: auto;
  /* background: url("../assets/img/445566.png") no-repeat center; */
  /* background-size: 100% 100%; */
  /* padding-top: 5%; */
  /* background-size: cover; */
  padding-top: 40px;
}
.topTitle {
}
.downloadQrCont {
  color: #fff;
  display: flex;
  margin-top: 50px;
  /* width: 50%; */
  align-items: flex-start;
  margin-left: 1;
}
.downloadmpQrCont {
  color: #fff;
  /* width: 50%; */
  text-align: center;
  margin-top: 50px;
}
.largeFrame {
  width: 42%;
  margin-left: 46%;
  text-align: center;
  position: absolute;
  top: 40%;
}
.smallFrame {
  display: flex;
}
.smallFrame-text {
  margin-top: 10%;
  font-size: 24px;
  color: #fff;
}
.smallFrame-img {
  width: 100%;
}
.smallFrame-imgs {
  width: 100%;
  margin-left: 2%;
}
.exit_hover .exit1 {
  display: inline-block;
}
.exit_hover .exit2 {
  display: none;
}
.exit_hover:hover .exit1 {
  display: none;
}
.exit_hover:hover .exit2 {
  width: 168px;
  height: 168px;
  display: inline-block;
}
.appLargeFrame {
  text-align: center;
  position: relative;
  top: 70%;
}
.appImg {
  width: 100%;
}
.appImg-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 9px;
}
.appImg-text {
  margin-top: 10%;
  font-size: 18px;
  color: #fff;
}
</style>
