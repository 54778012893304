<template>
  <div style="padding-bottom:30px">
    <p style="font-size:40px;text-align:center;font-weight:500;margin-top: 100px">合伙人意向申请表</p>
    <div>
      <div style="display:flex;justify-content:space-around;margin-top: 50px">
        <a-input v-model="name" style="width: 30%" size="large" addon-before="姓名（必填）" placeholder="请输入您的姓名" />
        <a-input v-model="phone" style="width: 30%" size="large" addon-before="联系方式（必填）" placeholder="请输入您的联系方式" />
      </div>
      <div style="display:flex;justify-content:space-around;margin-top: 30px">
        <a-input v-model="city" style="width: 30%" size="large"  addon-before="意向开发城市（必填）" placeholder="请输入您意向开发的城市" />
        <a-input v-model="message" style="width: 30%" size="large" addon-before="留言（选填）" placeholder="请输入您留言" />
      </div>
      <div style="text-align:center;margin-top: 30px;font-size:16px">
        <p>（我们将向您发送项目资料）</p>
        <p style="color:#1890ff">*投资有风险，立项需谨慎</p>
        <a-button @click="sentMessage" style="width:20%;margin-top: 20px" type="primary">提交申请</a-button>
      </div>
    </div>
  </div>
  
</template>
<script>
import qs from 'qs'
export default {
  data(){
    return{
      name:'',
      phone: '',
      city: '',
      message: ''
    }
  },
  mounted(){

  },
  methods:{
    sentMessage(){
      if(this.name && this.phone && this.city){
        this.$axios.post(
          this.$api.server_api.v1.SEND_MESSAGE,
          qs.stringify({
            name: this.name,
            tel: this.phone,
            city: this.city,
            message: this.message
          })
        ).then( res=>{
          if(res.data.code == 0){
            this.$message.success('发送成功');
            this.name = ''
            this.phone = ''
            this.city = ''
            this.message = ''
          }
        })
      }else{
        this.$message.warning('请填写完整信息');
      }

    }
  }
}
</script>