<template>
  <div>
    <Header :nowActive='nowActive'></Header>
    <div class="bgImg" >
      <img  style="width:100%"  src="../assets/img/cityPartner.jpg" alt="">
      <!-- <div class="bgImgMask">
          <div style="text-align:center;padding-top:10%">
            <p style="font-size: 50px;color:#fff;font-weight:500;line-height:80px">WELCOME TO JION US</p>
            <p style="font-size: 46px;color:#fff;font-weight:500;line-height:80px">梦想从这里启航</p>
            <p style="font-size: 48px;color:#fff;font-weight:500;line-height:80px">加入我们 共同创造未来</p>
            <div style="border:1px solid #fff;width:150px;height:50px;line-height:50px;margin:0 auto;margin-top:20px">
              <p style="color:#fff;font-size: 20px;font-weight:500">点击加入</p>
            </div>
          </div>
      </div> -->
    </div>

    <div style="margin-top:50px" v-if="isMobile == false">
      <p style="font-size:40px;text-align:center;font-weight:500;color:#54647f">服务种类齐全</p>
      <div class="cityPartnerlcCont" style="">
        <img class="cityPartnerlc"  src="../assets/img/kc.png" alt="">
        <a-icon style="font-size:30px;color:#ddd" type="double-right" />
        <img class="cityPartnerlc" style="width:135px" src="../assets/img/bm.png" alt="" srcset="">
        <a-icon style="font-size:30px;color:#ddd" type="double-right" />
        <img class="cityPartnerlc" style="width:135px" src="../assets/img/qy.png" alt="" srcset="">
        <a-icon style="font-size:30px;color:#ddd" type="double-right" />
        <img class="cityPartnerlc" src="../assets/img/yy.png" alt="" srcset="">
      </div>
      <div class="cityPartnerlcCont" style="">
        <p class="cityPartnerlcTit" style="width:135px">报名咨询</p>
        <a-icon style="font-size:30px;color:#ddd;visibility:hidden" type="double-right" />
        <p class="cityPartnerlcTit" style="text-align:center;width:120px;">双方考察</p>
        <a-icon style="font-size:30px;color:#ddd;visibility:hidden" type="double-right" />
        <p class="cityPartnerlcTit" style="width:135px">签约培训</p>
        <a-icon style="font-size:30px;color:#ddd;visibility:hidden" type="double-right" />
        <p class="cityPartnerlcTit" style="width:120px">运营支持</p>
      </div>
    </div>
    <div v-else style="margin-top:20px">
      <p style="font-size:20px;text-align:center;font-weight:500;color:#54647f">服务种类齐全</p>
      <a-row>
        <div class="cityPartnerlcCont" style="">
          <img class="cityPartnerlc" style="width:50px"  src="../assets/img/kc.png" alt="">
          <a-icon style="font-size:10px;color:#ddd" type="double-right" />
          <img class="cityPartnerlc" style="width:50px" src="../assets/img/bm.png" alt="" srcset="">
          <a-icon style="font-size:10px;color:#ddd" type="double-right" />
          <img class="cityPartnerlc" style="width:50px" src="../assets/img/qy.png" alt="" srcset="">
          <a-icon style="font-size:10px;color:#ddd" type="double-right" />
          <img class="cityPartnerlc" style="width:50px" src="../assets/img/yy.png" alt="" srcset="">
        </div>
      </a-row>
      <div class="cityPartnerlcCont" style="">
        <p class="cityPartnerlcTit" style="width:135px;font-size:15px">报名咨询</p>
        <a-icon style="font-size:30px;color:#ddd;visibility:hidden" type="double-right" />
        <p class="cityPartnerlcTit" style="text-align:center;width:120px;font-size:15px">双方考察</p>
        <a-icon style="font-size:30px;color:#ddd;visibility:hidden" type="double-right" />
        <p class="cityPartnerlcTit" style="width:135px;font-size:15px">签约培训</p>
        <a-icon style="font-size:30px;color:#ddd;visibility:hidden" type="double-right" />
        <p class="cityPartnerlcTit" style="width:120px;font-size:15px">运营支持</p>
      </div>
    </div>
    <div v-if="isMobile == false">
      <p style="font-size:40px;text-align:center;font-weight:500;margin-top: 30px;color:#54647f">加盟要求</p>
      <div style="display:flex;justify-content:space-around;margin-top:50px">
        <div class="jmyqCont" style="text-align:center">
          <img src="../assets/img/jmyqzj.png" style="width:35%" alt="">
          <p style="font-size:30px;color:#54647f;font-weight:500;margin-top:20px">资金人力</p>
          <p style="color:#54647f;font-size: 18px;;margin-top:20px;font-weight:500">拥有自己的团队及足够的启动资金</p>
        </div>
        <div class="jmyqCont" style="text-align:center">
          <img src="../assets/img/jmyqzz.png" style="width:35%" alt="">
          <p style="font-size:30px;color:#54647f;font-weight:500;margin-top:20px">办公条件</p>
          <p style="color:#54647f;font-size: 18px;;margin-top:20px;font-weight:500">合法的营业执照 合适的办公场所</p>
        </div>
        <div class="jmyqCont" style="text-align:center">
          <img src="../assets/img/jmyqgl.png" style="width:35%" alt="">
          <p style="font-size:30px;color:#54647f;font-weight:500;margin-top:20px">优先条件</p>
          <p style="color:#54647f;font-size: 18px;;margin-top:20px;font-weight:500">具有项目管理及市场运作经验</p>
          <p style="color:#54647f;font-size: 18px;;margin-top:5px;font-weight:500">具有众包类及同城配送运作经验优先</p>
        </div>
      </div>
    </div>
    <div v-else>
      <p style="font-size:20px;text-align:center;font-weight:500;margin-top: 30px;color:#54647f">加盟要求</p>
      <div style="display:flex;justify-content:space-around;margin-top:50px">
        <div class="jmyqCont" style="text-align:center">
          <img src="../assets/img/jmyqzj.png" style="width:35%" alt="">
          <p style="font-size:15px;color:#54647f;font-weight:500;margin-top:15px">资金人力</p>
          <p style="color:#54647f;font-size: 12px;;margin-top:20px;font-weight:500">拥有自己的团队及足够的启动资金</p>
        </div>
        <div class="jmyqCont" style="text-align:center">
          <img src="../assets/img/jmyqzz.png" style="width:35%" alt="">
          <p style="font-size:15px;color:#54647f;font-weight:500;margin-top:15px">办公条件</p>
          <p style="color:#54647f;font-size: 12px;;margin-top:20px;font-weight:500">合法的营业执照 合适的办公场所</p>
        </div>
        <div class="jmyqCont" style="text-align:center">
          <img src="../assets/img/jmyqgl.png" style="width:35%" alt="">
          <p style="font-size:15px;color:#54647f;font-weight:500;margin-top:15px">优先条件</p>
          <!-- <p style="color:#54647f;font-size: 12px;;margin-top:20px;font-weight:500">具有项目管理及市场运作经验</p> -->
          <p style="color:#54647f;font-size: 12px;;margin-top:20px;font-weight:500">具有众包类及同城配送运作经验优先</p>
        </div>
      </div>
    </div>
    <div v-if="isMobile == false" style="background:#fff;padding-top:30px;padding-bottom: 30px;">
      <p style="font-size:40px;text-align:center;font-weight:500;margin-top: 150px;color:#54647f">4大加盟优势</p>
      <div style="display:flex;justify-content:space-around;margin-top:50px">
        <div style="display:flex;background:#fff;width:40%">
          <img src="../assets/img/mscs.jpg" width="55%" alt="" srcset="">
          <div style="width:100%;text-align:center;background: #eff2f6">
            <p style="font-size:18px;margin-top:20%;font-weight:500;color:#54647f">模式成熟</p>
            <p style="text-align:center;margin-top:10px;color:#54647f;font-weight:500">百个城市区县运营经验总结</p>
          </div>
        </div>
        <div style="display:flex;background:#fff;width:40%">
          <img src="../assets/img/txws.jpg" width="55%" alt="" srcset="">
          <div style="width:100%;text-align:center;background: #eff2f6">
            <p style="font-size:18px;margin-top:20%;font-weight:500;color:#54647f">体系完善</p>
            <p style="text-align:center;margin-top:10px;color:#54647f;font-weight:500">自主研发多产品系统支持</p>
          </div>
        </div>         
      </div>
      <div style="display:flex;justify-content:space-around;margin-top:30px">
        <div style="display:flex;background:#fff;width:40%;height:30%">
          <img src="../assets/img/dmkfcd.jpg" width="55%" alt="" srcset="">
          <div style="width:100%;text-align:center;background: #eff2f6">
            <p style="font-size:18px;margin-top:20%;font-weight:500;color:#54647f">低门槛 扶持多</p>
            <p style="text-align:center;margin-top:10px;color:#54647f;font-weight:500">让大家成功创业 收获第一桶金</p>
          </div>
        </div>
        <div style="display:flex;background:#fff;width:40%">
          <img src="../assets/img/tzrql.jpg" width="55%" alt="" srcset="">
          <div style="width:100%;text-align:center;background: #eff2f6">
            <p style="font-size:18px;margin-top:20%;font-weight:500;color:#54647f">圆梦行动</p>
            <p style="text-align:center;margin-top:10px;color:#54647f;font-weight:500">成就事业 除了梦想 必须行动</p>
          </div>
        </div>         
      </div>
    </div>
    <div v-else style="background:#fff;padding-top:30px;padding-bottom: 30px;">
      <p style="font-size:20px;text-align:center;font-weight:500;margin-top: 20px;color:#54647f">4大加盟优势</p>
      <div style="margin-top:30px">
        <div style="display:flex;background:#fff;width:100%">
          <img src="../assets/img/mscs.jpg" width="55%" alt="" srcset="">
          <div style="width:100%;text-align:center;background: #eff2f6">
            <p style="font-size:18px;margin-top:20%;font-weight:500;color:#54647f">模式成熟</p>
            <p style="text-align:center;margin-top:10px;color:#54647f;font-weight:500">百个城市区县运营经验总结</p>
          </div>
        </div>
        <div style="display:flex;background:#fff;width:100%;margin-top: 20px">
          <img src="../assets/img/txws.jpg" width="55%" alt="" srcset="">
          <div style="width:100%;text-align:center;background: #eff2f6">
            <p style="font-size:18px;margin-top:20%;font-weight:500;color:#54647f">体系完善</p>
            <p style="text-align:center;margin-top:10px;color:#54647f;font-weight:500">自主研发多产品系统支持</p>
          </div>
        </div>         
      </div>
      <div style="margin-top:30px">
        <div style="display:flex;background:#fff;width:100%">
          <img src="../assets/img/dmkfcd.jpg" width="55%" alt="" srcset="">
          <div style="width:100%;text-align:center;background: #eff2f6">
            <p style="font-size:18px;margin-top:20%;font-weight:500;color:#54647f">低门槛 扶持多</p>
            <p style="text-align:center;margin-top:10px;color:#54647f;font-weight:500">让大家成功创业 收获第一桶金</p>
          </div>
        </div>
        <div style="display:flex;background:#fff;width:100%;margin-top: 20px">
          <img src="../assets/img/tzrql.jpg" width="55%" alt="" srcset="">
          <div style="width:100%;text-align:center;background: #eff2f6">
            <p style="font-size:18px;margin-top:20%;font-weight:500;color:#54647f">圆梦行动</p>
            <p style="text-align:center;margin-top:10px;color:#54647f;font-weight:500">成就事业 除了梦想 必须行动</p>
          </div>
        </div>         
      </div>
    </div>
    <cmap></cmap>
    <!-- <Table></Table> -->
    <Publicfooter></Publicfooter>
  </div>
</template>
<script>
import Header from '../components/header'
import cmap from '../components/cmap'
import Table from '../components/cooperationTable'
import Publicfooter from '../components/footer'
export default {
  data(){
    return{
      nowActive: [4],
      isMobile: false
    }
  },
  components:{
    Header,
    cmap,
    Table,
    Publicfooter
  },
  mounted(){
    if (this._isMobile()) {
      this.isMobile = true
      // this.$router.replace('/m_index');
    } else {
      this.isMobile = false
      // this.$router.replace('/pc_index');
    }
  },
  methods:{
　  _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
  }
}
</script>
<style scoped>
.bgImg{
  /* width: 100%;
  height:680px;
  background: url('../assets/img/cityPartner.jpg') no-repeat center;
  background-size: 100% 100%; */
}
.bgImgMask{
  width: 100%;
  height: 680px;
  background-color: rgba(0, 0, 0, 0.45);
}
.cityPartnerlc{
  width: 120px;
  
}
.cityPartnerlc:hover{
  animation: tada; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
}
.cityPartnerlcCont{
  display:flex;
  justify-content:space-around;
  width:90%;
  margin-left:5%;
  align-items: center;
  margin-top:20px
}
.cityPartnerlcTit{
  text-align:center;
  font-size: 25px;
  font-weight: 500;
  color: #54647f
}


.jmyqContInfo{
  width: 100%;
  text-align: center;
  background: #fff;
  height:30px;
  opacity: 0.9;
  position: relative;
  top:170px;
  font-size: 16px
}
.jmyq{
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-left: 5%;
  margin-top: 50px
}
.jmyqCont:hover{
  display: inline-block;
  animation: flip; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
}
</style>