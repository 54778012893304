let server_api = {
  'v1':{
    GET_BANNER : '/api/website/bannerList',//获取banner图
    FOOTER_BASIC_INFO: '/api/website/webInfo',//footer基本信息
    SEND_MESSAGE: '/api/website/saveMessage',//留言
    NAV_LIST: "/api/website/navList",//导航列表
    QRCODE_LIST: '/api/website/QRcodeList',//二维码列表
    RCHARGE: '/community/Recharge/pay',//微信充值
    ALIPAY: '/community/Recharge/aliPay',//支付宝充值
    RECHARGE_STATUS: '/api/Recharge/checkOrder',//微信查询充值状态
    ALIPAY_STATUS: '/api/Recharge/aliCheckOrder',//支付宝查询充值状态
  }
}
export default {
  server_api
}