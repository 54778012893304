var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isMobile==false)?_c('Header',{attrs:{"nowActive":_vm.nowActive}}):_vm._e(),_c('PublicBanner'),(_vm.isMobile == false)?_c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]):_c('div',[_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11)]),(_vm.isMobile==false)?_c('Publicfooter'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin-top":"2%"}},[_c('div',{staticStyle:{"width":"70%","display":"flex","margin":"0 auto"}},[_c('div',[_c('img',{attrs:{"src":require("../assets/img/1.jpg"),"alt":""}})]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","font-size":"16px","color":"black","width":"60%","margin-left":"3%"}},[_c('span',{staticStyle:{"text-indent":"2em"}},[_vm._v(" 快小象是国内独立“智能聚合”平台，专注解决自配送商户、线上平台、个人同城配送需求的，为所有客户提供高效、全面的同城配送及跑腿服务。始终坚持以客户为中心，通过5年下沉积累了大量科技研发成果，快速、高效、训练有素的团队快速获得市场认可，在全国范围内拥有众多合作伙伴。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin-top":"2%"}},[_c('div',{staticStyle:{"width":"70%","display":"flex","margin":"0 auto"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","font-size":"16px","color":"black","width":"60%"}},[_c('span',{staticStyle:{"text-indent":"2em"}},[_vm._v(" 快小象聚合平台是由北京欧信软件服务有限公司独立开发的全网智能运力调度平台，是国内创新的智能聚合配送服务平台，目前实现了消费场景全覆盖，通过构建智能化配送闭环，满足多角色全方位使用需求，为全国美饿等自配送商户、线上平台、个人提供商业配送、订单管理、日常取送件以及帮买帮办的一体化服务。 采用大数据+AI智能，聚焦共享经济，以聚合服务为依托，专注于专人直送、同城跑腿、专车快送、顺路配送、专业订单管理等服务，覆盖多元化需求，让用户省时省力。 ")])]),_c('div',{staticStyle:{"margin-left":"3%"}},[_c('img',{attrs:{"src":require("../assets/img/2.jpg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin-top":"2%"}},[_c('div',{staticStyle:{"width":"70%","display":"flex","margin":"0 auto"}},[_c('div',[_c('img',{attrs:{"src":require("../assets/img/3.jpg"),"alt":""}})]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","font-size":"16px","color":"black","width":"60%","margin-left":"3%"}},[_c('span',{staticStyle:{"text-indent":"2em"}},[_vm._v(" 创始团队历经5年时间研发与实战，于2021年成立快小象，目前员工逾百人，立足北京辐射全国，上线仅半年就覆盖超过10万家店铺，与饿了么、美团、京东、闪送、顺丰同城、达达快递、uu跑腿、蜂鸟配送、美团跑腿、阿里云、高德地图等平台合作，打造信息闭环。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin-top":"2%"}},[_c('div',{staticStyle:{"width":"70%","display":"flex","margin":"0 auto"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","font-size":"16px","color":"black","width":"60%"}},[_c('span',{staticStyle:{"text-indent":"2em"}},[_vm._v(" 快小象作为国内全新的“智能聚合”平台，以聚合服务为依托，专注于专人直送，同城跑腿，专车快送，顺路配送等服务，专业订单管理。聚合包含美团外卖、饿了么、京东等平台的订单及配送服务，覆盖外卖餐饮配送、鲜花配送、饮品配送、蛋糕配送、日用百货、个人物品等多项服务，轻松覆盖更多生活需求。 快小象目前已覆盖350个市级城市及上千个城乡地区，汇聚百个城市区县运营经验总结，拥有无数成功案例，通过优质服务，赢得了用户认可。 ")])]),_c('div',{staticStyle:{"margin-left":"3%"}},[_c('img',{attrs:{"src":require("../assets/img/4.jpg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin-top":"2%"}},[_c('div',{staticStyle:{"width":"70%","display":"flex","margin":"0 auto"}},[_c('div',[_c('img',{attrs:{"src":require("../assets/img/5.jpg"),"alt":""}})]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","font-size":"16px","color":"black","width":"60%","margin-left":"3%"}},[_c('span',{staticStyle:{"text-indent":"2em"}},[_vm._v(" 全新的聚合速递服务，一方呼叫，八方相应。 7天×24小时，订单平均1分钟响应、10分钟上门、同城1小时送达，做到了实时轨迹回传，打造了覆盖多种配送需求的定制化服务。 专业同城快送，提供骑手+专车配送服务，为自配送商户提供更快速配送解决方案，提高复购率与好评率。 全面生活服务，联合提供更多到家服务，一站式解决更多客群的延伸需求。 万能跑腿服务，帮购物，帮排队，帮取件，打通更多服务场景，解决各种生活场景跑腿需求。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin-top":"2%"}},[_c('div',{staticStyle:{"width":"70%","display":"flex","margin":"0 auto"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","font-size":"16px","color":"black","width":"60%"}},[_c('span',{staticStyle:{"text-indent":"2em"}},[_vm._v(" 近年来同城配送市场迎来激烈竞争，产业格局发生了巨大变化，快小象通过5年潜心打磨服务，下沉深挖共享经济优势，拓展服务半径，自主研发多产品系统支持，辐射到家服务、跑腿服务等多个领域，打破了配送服务市场被垄断的局面，目前运营模式已经趋于成熟，打开了智能配送的大门，将会深刻影响电商和配送产业的发展。 ")])]),_c('div',{staticStyle:{"margin-left":"3%"}},[_c('img',{attrs:{"src":require("../assets/img/6.jpg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin-top":"6%"}},[_c('div',{staticStyle:{"width":"90%","margin":"0 auto"}},[_c('div',[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../assets/img/1.jpg"),"alt":""}})]),_c('div',{staticStyle:{"align-items":"center","font-size":"16px","color":"black","margin-top":"2%"}},[_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v(" 快小象是国内独立“智能聚合”平台，专注解决自配送商户、线上平台、个人同城配送需求的，为所有客户提供高效、全面的同城配送及跑腿服务。始终坚持以客户为中心，通过5年下沉积累了大量科技研发成果，快速、高效、训练有素的团队快速获得市场认可，在全国范围内拥有众多合作伙伴。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin-top":"6%"}},[_c('div',{staticStyle:{"width":"90%","margin":"0 auto"}},[_c('div',[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../assets/img/2.jpg"),"alt":""}})]),_c('div',{staticStyle:{"align-items":"center","font-size":"16px","color":"black","margin-top":"2%"}},[_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v(" 快小象聚合平台是由北京欧信软件服务有限公司独立开发的全网智能运力调度平台，是国内创新的智能聚合配送服务平台，目前实现了消费场景全覆盖，通过构建智能化配送闭环，满足多角色全方位使用需求，为全国美饿等自配送商户、线上平台、个人提供商业配送、订单管理、日常取送件以及帮买帮办的一体化服务。 采用大数据+AI智能，聚焦共享经济，以聚合服务为依托，专注于专人直送、同城跑腿、专车快送、顺路配送、专业订单管理等服务，覆盖多元化需求，让用户省时省力。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin-top":"6%"}},[_c('div',{staticStyle:{"width":"90%","margin":"0 auto"}},[_c('div',[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../assets/img/3.jpg"),"alt":""}})]),_c('div',{staticStyle:{"align-items":"center","font-size":"16px","color":"black","margin-top":"2%"}},[_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v(" 创始团队历经5年时间研发与实战，于2021年成立快小象，目前员工逾百人，立足北京辐射全国，上线仅半年就覆盖超过10万家店铺，与饿了么、美团、京东、闪送、顺丰同城、达达快递、uu跑腿、蜂鸟配送、美团跑腿、阿里云、高德地图等平台合作，打造信息闭环。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin-top":"6%"}},[_c('div',{staticStyle:{"width":"90%","margin":"0 auto"}},[_c('div',[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../assets/img/4.jpg"),"alt":""}})]),_c('div',{staticStyle:{"align-items":"center","font-size":"16px","color":"black","margin-top":"2%"}},[_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v(" 快小象作为国内全新的“智能聚合”平台，以聚合服务为依托，专注于专人直送，同城跑腿，专车快送，顺路配送等服务，专业订单管理。聚合包含美团外卖、饿了么、京东等平台的订单及配送服务，覆盖外卖餐饮配送、鲜花配送、饮品配送、蛋糕配送、日用百货、个人物品等多项服务，轻松覆盖更多生活需求。 快小象目前已覆盖350个市级城市及上千个城乡地区，汇聚百个城市区县运营经验总结，拥有无数成功案例，通过优质服务，赢得了用户认可。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin-top":"6%"}},[_c('div',{staticStyle:{"width":"90%","margin":"0 auto"}},[_c('div',[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../assets/img/5.jpg"),"alt":""}})]),_c('div',{staticStyle:{"align-items":"center","font-size":"16px","color":"black","margin-top":"2%"}},[_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v(" 全新的聚合速递服务，一方呼叫，八方相应。 7天×24小时，订单平均1分钟响应、10分钟上门、同城1小时送达，做到了实时轨迹回传，打造了覆盖多种配送需求的定制化服务。 专业同城快送，提供骑手+专车配送服务，为自配送商户提供更快速配送解决方案，提高复购率与好评率。 全面生活服务，联合提供更多到家服务，一站式解决更多客群的延伸需求。 万能跑腿服务，帮购物，帮排队，帮取件，打通更多服务场景，解决各种生活场景跑腿需求。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin-top":"6%"}},[_c('div',{staticStyle:{"width":"90%","margin":"0 auto"}},[_c('div',[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../assets/img/5.jpg"),"alt":""}})]),_c('div',{staticStyle:{"align-items":"center","font-size":"16px","color":"black","margin-top":"2%"}},[_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v(" 近年来同城配送市场迎来激烈竞争，产业格局发生了巨大变化，快小象通过5年潜心打磨服务，下沉深挖共享经济优势，拓展服务半径，自主研发多产品系统支持，辐射到家服务、跑腿服务等多个领域，打破了配送服务市场被垄断的局面，目前运营模式已经趋于成熟，打开了智能配送的大门，将会深刻影响电商和配送产业的发展。 ")])])])])
}]

export { render, staticRenderFns }