var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isMobile==false)?_c('Header',{attrs:{"nowActive":_vm.nowActive}}):_vm._e(),(_vm.isMobile == false)?_c('div',{staticClass:"downloadAppCont"},[_c('img',{staticStyle:{"width":"40%","margin-top":"5%","margin-left":"3%"},attrs:{"src":require("../assets/img/phone.png"),"alt":""}}),_c('div',{staticClass:"largeFrame"},[_c('div',{staticClass:"smallFrame"},[_c('div',{staticClass:"smallFrame-img"},[_c('a',{attrs:{"href":_vm.IOSUrl}},[_c('div',{staticClass:"exit_hover"},[_c('img',{staticClass:"exit1",attrs:{"src":require("../assets/img/app.png")}}),_c('img',{staticClass:"exit2",attrs:{"src":_vm.IOSQR}})])]),_vm._m(0)]),_c('div',{staticClass:"smallFrame-imgs"},[_c('a',{attrs:{"href":_vm.AndroidUrl}},[_c('div',{staticClass:"exit_hover"},[_c('img',{staticClass:"exit1",attrs:{"src":require("../assets/img/and.png")}}),_c('img',{staticClass:"exit2",attrs:{"src":_vm.AndroidQR}})])]),_vm._m(1)]),_vm._m(2),_c('div',{staticClass:"smallFrame-imgs"},[_c('div',{staticClass:"exit_hover"},[_c('img',{staticClass:"exit1",attrs:{"src":require("../assets/img/wx.png")}}),_c('img',{staticClass:"exit2",attrs:{"src":_vm.miniProgram}})]),_vm._m(3)])])])]):_c('div',{staticClass:"downloadAppMobileCont",style:({height:_vm.screenHeight})},[_c('div',{staticClass:"appLargeFrame"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"appImg"},[_c('a',{attrs:{"href":_vm.IOSUrl}},[_c('img',{staticClass:"appImg-img",attrs:{"src":require("../assets/img/app.png")}})]),_vm._m(4)]),_c('div',{staticClass:"appImg"},[_c('a',{attrs:{"href":_vm.AndroidUrl}},[_c('img',{staticClass:"appImg-img",attrs:{"src":require("../assets/img/and.png")}})]),_vm._m(5)]),_vm._m(6),_c('div',{staticClass:"appImg"},[_c('img',{staticClass:"appImg-img",attrs:{"src":_vm.miniProgram}}),_vm._m(7)])])])]),(_vm.isMobile==false)?_c('Publicfooter'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smallFrame-text"},[_c('p',[_vm._v("IOS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smallFrame-text"},[_c('p',[_vm._v("Android")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smallFrame-imgs"},[_c('img',{attrs:{"src":require("../assets/img/win.png"),"alt":"","srcset":""}}),_c('div',{staticClass:"smallFrame-text"},[_c('p',[_vm._v("Windows")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smallFrame-text"},[_c('p',[_vm._v("小程序")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"appImg-text"},[_c('p',[_vm._v("IOS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"appImg-text"},[_c('p',[_vm._v("Android")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"appImg"},[_c('img',{staticClass:"appImg-img",attrs:{"src":require("../assets/img/win.png")}}),_c('div',{staticClass:"appImg-text"},[_c('p',[_vm._v("Windows")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"appImg-text"},[_c('p',[_vm._v("小程序")])])
}]

export { render, staticRenderFns }