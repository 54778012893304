import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import animated from 'animate.css' // npm install animate.css --save安装，在引入
import './assets/css/reset.css'
import echarts from "echarts";  
import axios from 'axios'
import api from '../public/http/api.js'
import VConsole from 'vconsole';
import http from '../public/http/http.js'
import Distpicker from 'v-distpicker'
import store from 'storejs';
import Vant from 'vant';
import 'vant/lib/index.css';
import iView from 'iview';
import 'iview/dist/styles/iview.css'
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'

// const vConsole = new VConsole();
Vue.component('v-distpicker', Distpicker)

Vue.prototype.$echarts = echarts  
Vue.prototype.$api = api  
Vue.prototype.$axios = axios
Vue.use(animated)
Vue.config.productionTip = false
Vue.use(Antd).use(Vant);
Vue.use(iView);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})