<template>
  <div style="width: 100%;">
    <Carousel v-model="value1" :autoplay="true" arrow="always" :autoplay-speed="3000"> 
        <Carousel-item v-for="item in bannerList">
          <img style="display: inline-block;height: auto;width: 100%;radius:300px;" :src="item.thumb" alt="" srcset="">
        </Carousel-item>
    </Carousel>
  </div>
</template>
<script>
export default {
  data(){
    return{
      bannerList: [],
      value1: 1
    }
  },
  mounted(){
    this.getBanner()

  },
  methods:{
    getBanner(){
      this.$axios.post(
        this.$api.server_api.v1.GET_BANNER
      ).then( res=>{
        this.bannerList = res.data.data
      })
    },
  }
}
</script>
<style scoped>
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: auto;
  overflow: hidden;
  background-size: cover;
  width: 100%;
}

</style>