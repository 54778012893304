<template>
<div>
  <div v-if="isMobile == false">
    <div>
      <p style="font-size:40px;text-align:center;font-weight:500;margin-top: 150px;color:#54647f">将开通众多城市</p>
    </div>
    <div class="Echarts">
      <div class="cmapInfoCont" style="">
        <p style="font-size: 24px;color:#54647f;font-weight:500;">扫码关注公众号</p>
        <img style="width:70%;margin-top:10px" :src="gzh" alt="" srcset="">
        <div style="margin-top: 10px;font-size: 20px;line-height:36px;font-weight:500">
  
          <!-- <p style="color:#54647f">总部全程运营支持</p> -->
        </div>
        <div style="margin-top:20px">
          <span style="width:50px;height:8px;background:#54647f;display:inline-block;border-radius:4px"></span>
          <p style="color:#54647f;margin-top:40px;font-size:20px">全国热线：{{basicInfoList.tel}}</p>
        </div>
      </div>
      <div id="backImg" style="width:100%;height:100%;background-size: cover; -moz-background-size: cover; "><!-- 外层div用来放背景图片 -->
        <div id="main" style="width: 100%;height:400px;"></div>
      </div>
    </div>
  </div>
  <div v-else>
    <div>
      <p style="font-size:20px;text-align:center;font-weight:500;margin-top: 30px;color:#54647f">将开通众多城市</p>
    </div>
    <div class="Echarts">
      <div class="cmapInfoContMobile" style="">
        <p style="font-size: 18px;color:#54647f;font-weight:500;">扫码关注公众号</p>
        <img style="width:70%;margin-top:10px" :src="gzh" alt="" srcset="">
        <div style="margin-top: 10px;font-size: 20px;line-height:36px;font-weight:500">
  
          <!-- <p style="color:#54647f">总部全程运营支持</p> -->
        </div>
        <div style="margin-top:15px">
          <span style="width:50px;height:8px;background:#54647f;display:inline-block;border-radius:4px"></span>
          <p style="color:#54647f;margin-top:15px;font-size:15px">全国热线：{{basicInfoList.tel}}</p>
        </div>
      </div>
      <!-- <div id="backImg" style="width:100%;height:100%;background-size: cover; -moz-background-size: cover; ">
        <div id="main" style="width: 100%;height:400px;"></div>
      </div> -->
    </div>
  </div>
</div>

</template>
<script>
import '../assets/js/china.js'

export default {
  name: 'Echarts',
  data(){
    return{
      basicInfoList :[],
      gzh: '',
      isMobile: false
    }
  },
  mounted() {
    this.myEcharts();
    this.getBasicInfo()
    this.getQRcode()
    if (this._isMobile()) {
      this.isMobile = true
      // this.$router.replace('/m_index');
    } else {
      this.isMobile = false
      // this.$router.replace('/pc_index');
    }
  },
  methods:{
    myEcharts(){
		  // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById('main'));

		  // 指定图表的配置项和数据
      var option = {
          geo: { // 这个是重点配置区
            map: 'china', // 表示中国地图,
            left: '50%',
            label: {
              normal:{
                show: false, // 是否显示对应地名
                textStyle: { //字体颜色
                  color: '#797979'
                }
              },
              emphasis: {
                show: true,
                textStyle: {
                  color: '#fff'
                }
              }
            },
            roam: false,
            itemStyle: {
              normal: {
                borderWidth: 1, // 地图边框宽度
                borderColor: '#ccc', // 地图边框颜色
                areaColor: '#ade9ff' // 地图颜色
              },
              emphasis: {//选中省份的颜色
                areaColor: '#10bfdc',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 0,
                borderWidth: 1,
                shadowColor: '#fff',
              }
            }
          },
          //滑动显示数据
          tooltip: {
              trigger: 'item',
              formatter: function(params) {
                  return params.name + ' 已接入: ' + params.data.num[2];
              }
          },
          series: [{
              name: '散点',
              type: 'scatter',
              coordinateSystem: 'geo',
              zoom: 1.1,
              color:"#e1ebe3",//点的颜色
              symbolSize: 25,//点的大小
              symbol:"pin",//点的样式
              cursor:"pointer",//鼠标放上去的效果
              label: {
                normal: {//默认展示
                    show: false
                },
                emphasis: {//滑过展示
                    show: false
                }
              },
              itemStyle: {
                emphasis: {
                  borderColor: '#5c8f6e',
                  borderWidth: 5
                }
              }
            },
            {
              type: 'map',
              map: 'china',
              geoIndex: 0,
              aspectScale: 0.75,
              tooltip: {
                  show: false
              }
            },
              
          ],
        
        // if (m
        
      };
		  // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
		},
　  _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    getBasicInfo(){
      this.$axios.post(
        this.$api.server_api.v1.FOOTER_BASIC_INFO
      ).then( res=> {
        this.basicInfoList = res.data.data
      })
    },
    getQRcode(){
      this.$axios.post(
        this.$api.server_api.v1.QRCODE_LIST
      ).then( res=> {
        // this.QRcdoeList = res.data.data
        for( let i in res.data.data){
          if( res.data.data[i].platform == 4){
            this.gzh = res.data.data[i].thumb
          }
          // else if(res.data.data[i].platform == 2){
          //   this.IOSQR =  res.data.data[i].thumb
          // }else if(res.data.data[i].platform == 3){
          //   this.miniProgram = res.data.data[i].thumb
          // }
        }
      })
    }
  },

}
</script>

<style>
#backImg{
  /* background:url('../assets/img/mapbg.png') no-repeat center; */
  background-size: 100% 100%;
  
}
.Echarts{
  margin-top: 20px;
  /* padding-bottom: 150px; */
  padding-bottom: 40px
}
.cmapInfoCont{
  position:absolute;
  left:5%;
  margin-top: -30px;
  background:#fff;
  padding: 30px;
  text-align:center;
  box-shadow: 0 0px 5px  #666;
  z-index: 5;
  height: 400px;
}
.cmapInfoContMobile{
  background:#fff;
  padding: 30px 30px 300px 30px;
  width:70%;
  text-align:center;
  box-shadow: 0 0px 5px  #666;
  z-index: 7;
  height: 300px;
  margin-left:15%;
  /* padding-bottom: 30px */
}
</style>
