<template>
  <div>
    <Header :nowActive="nowActive"></Header>
    <div class="loginCont">
      <div style="background:#fff;width:30%;padding:3%;padding-right:3%">
        <a-input size="large" style="margin-top: 20px" placeholder="请输入手机号码" />
        <a-input size="large" type="password" addon-after="发送验证码"  style="margin-top: 20px" placeholder="请输入验证码" />
        <a-input size="large" type="password" style="margin-top: 20px" placeholder="请输入新密码" />
        <a-input size="large" type="password" style="margin-top: 20px" placeholder="确认密码" />
        <a-button size="large" type='primary' style="width:100%;margin-top: 50px">确定</a-button>
        <p style="text-align:center;margin-top:10px;color:#c2c2c2">记得密码？<span style="cursor:pointer;color:#666" @click="login">直接登录</span></p>

      </div>
    </div>
    <Publicfooter></Publicfooter>
  </div>
</template>
<script>
import Header from '../components/header' 
import Publicfooter from '../components/footer'
export default {
  components:{
    Header,
    Publicfooter
  },
  data(){
    return{
      nowActive: ['user']
    }
  },
  methods:{
    login(){
      this.$router.push('/personalEntrance')
    }
  }
}
</script>
<style scoped>
.loginCont{
  width:100%;
  height: 700px;
  background: url('../assets/img/loginbg.png') no-repeat center;
  background-size: 100% 120%;
  padding-top: 5%;
  padding-left: 10%
}
</style>